import React from 'react'
//import UpDownIcon from '../Logos/UpDownIcon'
//import AvailableDrawerRegistrar from './AvailableDrawerRegistrar'
import AvailableFeatureRegistrar from './AvailableFeatureRegistrar'

function Registrar({tld_id,domain}) {
  return (
    <>
        <div className="pt-0 section-row">
            <div className="container">
                <div className="small-container">
                    <div className="p-0 bg-white trusted-partner-card-box card-body text-md-center">
                        <h3 className="d-none d-md-block">Select a trusted retail partner.</h3>
                        <h3 className="d-md-none">Select a trusted retail partner to continue.</h3>
                        <p className="d-none d-md-block">We’ll redirect you to their site to complete your purchase.</p>
                        <p className="d-md-none">Follow the link to a partner site to purchase your domain.</p>
                        <div className="pt-2 trusted-partner-list">
                            <AvailableFeatureRegistrar tld_id = {tld_id} domain={domain}/>
                            {/* <div className="trusted-all-options-btn">
                                <a className="trusted-options-triger collapsed" data-bs-toggle="collapse" href="#trustedPartnerAll1" role="button" aria-expanded="false" aria-controls="trustedPartnerAll1">
                                    <span>Show All Options
                                       <UpDownIcon/>
                                    </span>
                                </a>
                            </div>
                            <div className="collapse" id="trustedPartnerAll1">
                                <AvailableDrawerRegistrar tld_id = {tld_id}/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </>
  )
}

export default Registrar