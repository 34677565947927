import React from "react";

function PoliciesBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-banner d-flex align-items-end align-items-md-center">
          <div
            className="banner-img d-none d-md-block"
            style={{
                backgroundImage: `url(/images/Policies_banner.jpg)`,
              }}
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
                backgroundImage: `url(/images/Policies_banner.jpg)`,
              }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="page-title-text text-center text-white">
                  <h1 className="text-white mb-0 pb-0">Policies</h1>
                  <p className="mt-2 m-0 font-xl">
                    Designed to safeguard and promote the public interest
                    delivering a safe, reliable namespace. See specific domain
                    requirements and policies below.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoliciesBanner;
