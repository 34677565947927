import React from "react";
import TldDetailSearchbox from "./TldDetailSearchbox";

function BannerTldDetailTemplate(props) {
  let bgimage = props.tldInfo["header_section"].bgimage;
  let mobile_bgimage = props.tldInfo["header_section"].mobile_bgimage;

  let folder_bgimage = props.tldInfo["header_section"].folder_bgimage;
  let folder_mobile_bgimage = props.tldInfo["header_section"].folder_mobile_bgimage;

  let url_bgimage = "";  
  if (bgimage !== "") {
    url_bgimage = process.env.REACT_APP_TLD_IMAGE_URL + "" + bgimage;
  } else {
    url_bgimage = "/images/tld-details-page-img/" + folder_bgimage;
  }

  let url_mobile_bgimage = "";
  if (mobile_bgimage !== "") {
    url_mobile_bgimage = process.env.REACT_APP_TLD_IMAGE_URL + "" + mobile_bgimage;
  } else {
    url_mobile_bgimage = "/images/tld-details-page-img/" + folder_mobile_bgimage;
  }

  return (
    <>
      <div className={`tld-banner-box ${props.tldName}`}>
        <div className="hero-banner-slider-box d-flex align-items-end align-items-md-center">
          <div
            className="banner-img d-none d-md-block"
            style={{
              backgroundImage: `url(${url_bgimage})`,
            }}
          ></div>

          <div
            className="banner-img d-block d-md-none"
            style={{
              backgroundImage: `url(${url_mobile_bgimage})`,
              //backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${props.tldInfo["header_section"].mobile_bgimage})`,
            }}
          ></div>

          <div className="container">
            <div className="tld-text mt-md-auto text-white">
              <h1 className="text-white mb-0 mb-md-4 pb-3 h2">
                {props.tldInfo["header_section"].header_text}
              </h1>
              <div className="tld-search">
                <TldDetailSearchbox
                  tldName={props.tldName}
                  tld_search_placeholder={
                    props.tldInfo["header_section"].tld_search_placeholder
                  }
                  searchbox_button_text={
                    props.tldInfo["header_section"].searchbox_button_text
                  }
                />
              </div>
              {props.tldInfo["header_section"].internationalization_link ? (
                <div
                  className="tld-link"
                  dangerouslySetInnerHTML={{
                    __html:
                      props.tldInfo["header_section"].internationalization_link,
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerTldDetailTemplate;
