import React from "react";
import GoogleMapReact from "google-map-react";

function MapContainer() {
  const ModelsMap = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 33.428897, lng: -111.941009 },
      map,
      label: "Godaddy",
    });
  };

  return (
    <div className="contact-map-frame">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={{ lat: 33.428897, lng: -111.941009 }}
        defaultZoom={15}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
      ></GoogleMapReact>
    </div>
  );
}

export default MapContainer;
