import React from "react";
import ReactTooltip from "react-tooltip";

function UnsupportedTlds({ domain }) {
  return (
    <>
      <div className="pt-md-4 pb-md-5 py-4">
        <div className="container">
          <div className="small-container">
            <div className="row g-3">
              <div className="col-md-12">
                <div className="h-100 d-flex flex-column justify-content-center py-4 px-4 tld-info-box bg-light tld-unsupported">
                  <div className="mt-3 mt-md-4 mb-4 d-flex">
                    <figure className="me-2 me-md-3 mb-0">
                      <svg
                        width="40"
                        height="39"
                        viewBox="0 0 40 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 0C16.1433 0 12.3731 1.14366 9.16639 3.28634C5.95963 5.42903 3.46027 8.47451 1.98436 12.0377C0.508449 15.6008 0.122284 19.5216 0.874696 23.3043C1.62711 27.0869 3.4843 30.5615 6.21143 33.2886C8.93855 36.0157 12.4131 37.8729 16.1957 38.6253C19.9784 39.3777 23.8992 38.9916 27.4623 37.5157C31.0255 36.0397 34.071 33.5404 36.2137 30.3336C38.3564 27.1269 39.5 23.3567 39.5 19.5C39.494 14.3301 37.4377 9.37368 33.782 5.71801C30.1263 2.06235 25.1699 0.00597081 20 0ZM3.50001 19.5C3.49759 16.3643 4.38943 13.293 6.07089 10.6462C7.75236 7.9995 10.1537 5.88715 12.9933 4.55697C15.8329 3.2268 18.9929 2.73396 22.1026 3.13627C25.2124 3.53858 28.143 4.81936 30.5506 6.82836L7.32837 30.0505C4.85271 27.0925 3.49737 23.3573 3.50001 19.5ZM20 36C16.1427 36.0026 12.4075 34.6473 9.44947 32.1716L32.6716 8.94946C34.6806 11.357 35.9614 14.2876 36.3637 17.3974C36.7661 20.5071 36.2732 23.6671 34.943 26.5067C33.6129 29.3463 31.5005 31.7477 28.8538 33.4291C26.207 35.1106 23.1357 36.0024 20 36Z"
                          fill="#444444"
                        ></path>
                      </svg>
                    </figure>
                    <div>
                      <h3 className="d-flex flex-wrap align-items-center">
                        <strong>{domain}</strong> is not available through this
                        search.
                        <figure>
                          <div className="app">
                            <button data-tip data-for="registerTip">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 0.693115C6.55373 0.693115 5.13993 1.12199 3.9374 1.92549C2.73486 2.729 1.7976 3.87106 1.24413 5.20724C0.690668 6.54343 0.545856 8.01373 0.828011 9.43221C1.11017 10.8507 1.80661 12.1537 2.82929 13.1763C3.85196 14.199 5.15492 14.8955 6.57341 15.1776C7.99189 15.4598 9.46219 15.315 10.7984 14.7615C12.1346 14.208 13.2766 13.2708 14.0801 12.0682C14.8836 10.8657 15.3125 9.45189 15.3125 8.00562C15.3103 6.06691 14.5391 4.20825 13.1682 2.83737C11.7974 1.4665 9.93871 0.695354 8 0.693115ZM8 14.1931C6.77623 14.1931 5.57994 13.8302 4.56241 13.1503C3.54488 12.4704 2.75182 11.5041 2.2835 10.3735C1.81518 9.24285 1.69265 7.99875 1.93139 6.79849C2.17014 5.59824 2.75944 4.49573 3.62478 3.63039C4.49012 2.76505 5.59263 2.17575 6.79288 1.93701C7.99314 1.69826 9.23724 1.82079 10.3679 2.28911C11.4985 2.75743 12.4648 3.5505 13.1447 4.56802C13.8246 5.58555 14.1875 6.78184 14.1875 8.00562C14.1856 9.64606 13.5331 11.2188 12.3731 12.3787C11.2131 13.5387 9.64044 14.1912 8 14.1931Z"
                                  fill="#111111"
                                ></path>
                                <path
                                  d="M8 7.44312C7.85083 7.44316 7.70778 7.50243 7.6023 7.60791C7.49682 7.71339 7.43754 7.85644 7.4375 8.00562V11.7556C7.4375 11.9048 7.49677 12.0479 7.60226 12.1534C7.70775 12.2589 7.85082 12.3181 8 12.3181C8.14919 12.3181 8.29226 12.2589 8.39775 12.1534C8.50324 12.0479 8.5625 11.9048 8.5625 11.7556V8.00562C8.56246 7.85644 8.50319 7.71339 8.39771 7.60791C8.29223 7.50243 8.14918 7.44316 8 7.44312Z"
                                  fill="#111111"
                                ></path>
                                <path
                                  d="M7.99781 4.34937C7.77411 4.35 7.55982 4.43942 7.40202 4.59798C7.24422 4.75653 7.15582 4.97125 7.15625 5.19495C7.15662 5.36175 7.20641 5.52469 7.29934 5.66321C7.39227 5.80172 7.52417 5.90958 7.67838 5.97316C7.83259 6.03674 8.00218 6.05319 8.16573 6.02043C8.32928 5.98767 8.47945 5.90716 8.59727 5.78909C8.71509 5.67102 8.79527 5.52067 8.82767 5.35705C8.86008 5.19343 8.84327 5.02387 8.77935 4.86981C8.71544 4.71574 8.60729 4.58407 8.46858 4.49144C8.32986 4.3988 8.1668 4.34937 8 4.34937H7.99781Z"
                                  fill="#111111"
                                ></path>
                              </svg>
                            </button>
                            <ReactTooltip
                              id="registerTip"
                              place="top"
                              effect="solid"
                            >
                              The domain extension you have searched for is not 
                              a GoDaddy Registry top-level domain. Visit our 
                              ‘Domain Extensions’ page to see what top-level
                              domains are available on this website.
                            </ReactTooltip>
                          </div>
                        </figure>
                      </h3>
                      <p className="font-l pt-1 mb-0">
                        Here are some other similar names
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UnsupportedTlds;
