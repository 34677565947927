import React from "react";

import DomainAvailBox from "./DomainAvailBox";
import DomainUnavailBox from "./DomainUnavailBox";

function DomainDisplayBox({ domains }) {
  return (
    <div className="pt-md-4 pb-md-5 py-4">
      <div className="container">
        <div className="small-container">
          <div className="row g-3">
            {domains.length >= 0
              ? domains.map((domain, index) => {
                  return (
                    <div className="col-md-6" key={index}>
                      {domain.data.status === "Available" ? (
                        <DomainAvailBox domain={domain} />
                      ) : (
                        <DomainUnavailBox
                          domain={domain}
                          whois={domain.data.info}
                        />
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainDisplayBox;
