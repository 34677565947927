import React, { useState, useEffect } from "react";
import HomeFooter from "../Footer/HomeFooter";
import WhiteNavBar from "../Partial/WhiteNavBar";
import ContactBanner from "./ContactBanner";
import ContactMapForm from "./ContactMapForm";
import Pageloader from "../Partial/Pageloader";
import {Helmet} from "react-helmet";

function ContactUs() {
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 300);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact GoDaddy Registry &#124; GoDaddy Registry Domain Hub</title> 
        <meta name="website" content="http://domains.registry.godaddy/contact"/>
        <meta name="description" content="Want to get in touch with GoDaddy Registry? We’d love to hear from you so why not drop us a line and someone will be in touch."/>
      </Helmet>
      <WhiteNavBar />
      {timer === true ? (
        <>
          <ContactBanner />
          <ContactMapForm />
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default ContactUs;
