import axios from "axios";

export const getAllTlds = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/home/getAllTld_chiplogic.php?site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllTlds API", error);
  }
};

export const getAllCategories = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/home/getCategoryInfo.php?site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllCategories API", error);
  }
};

export const getSpotlightInfo = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/home/getSpotlightInfo.php?site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data[0];
  } catch (error) {
    console.log("errror while calling getSpotlightInfo API", error);
  }
};

export const getAllChannelPartner = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/home/getAllChannelPartnerHome.php?site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllTlds API", error);
  }
};

export const getSuggestedDomain = async (domain, tldListArr) => {
  try {
    const tldList = tldListArr.join(",");
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/search/getSuggestedDomainList.php?domain=${domain}&tld=${tldList}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getSuggestedDomain API", error);
  }
};

export const getSuggestedDomainWithLimit = async (
  domain,
  tldListArr,
  limit
) => {
  try {
    const tldList = tldListArr.join(",");

    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/search/getSuggestedDomainWithLimit.php?domain=${domain}&tld=${tldList}&max_results=${limit}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getSuggestedDomainWithLimit API", error);
  }
};

export const getTldInfo = async (tld_name) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/home/getTldInfo.php?tld=${tld_name}&site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getTldInfo API", error);
  }
};

export const getAllfeaturedChannelPartner = async (tld_id) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}cms/hub/home/hub_featured_channel_partner.php?site_key=${process.env.REACT_APP_SITE_KEY}&tld_id=${tld_id}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllfeaturedChannelPartner API", error);
  }
};

export const getAllDrawerChannelPartners = async (tld_id) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}cms/hub/home/hub_all_channel_partners.php?site_key=${process.env.REACT_APP_SITE_KEY}&tld_id=${tld_id}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllDrawerChannelPartners API", error);
  }
};

export const getAllChannelPartners = async (filter) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}cms/hub/home/getAllChannelPartners.php?filter=${filter}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllChannelPartners API", error);
  }
};

export const getTrackSearchAPI = async (
  domain,
  tldId,
  pageRef,
  utmSource,
  utmCampaign,
  utmMedium,
  utmTerm,
  availability,
  search_page_id
) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/track/search/?s=${process.env.REACT_APP_SITE_KEY}&tld_id=${tldId}&availability=${availability}&t=${domain}&pageRef=${pageRef}&utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}&utm_term=${utmTerm}&search_page_id=${search_page_id}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getTrackSearch API", error);
  }
};

export const getAllAccreditedRegistrars = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}cms/hub/home/getAllAccreditedRegistrars.php?site_key=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllChannelPartners API", error);
  }
};
