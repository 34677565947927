import React, { useState, useEffect } from "react";
import HomeFooter from "../Footer/HomeFooter";
import WhiteNavBar from "../Partial/WhiteNavBar";
import FaqBanner from "./FaqBanner";
import FaqQuestionAnswers from "./FaqQuestionAnswers";
import Pageloader from "../Partial/Pageloader";
import {Helmet} from "react-helmet";

function Faq() {
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 300);
  }, []);
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions &#124; GoDaddy Registry Domain Hub</title> 
        <meta name="website" content="http://domains.registry.godaddy/faq"/>
        <meta name="description" content="Everything you wanted to know about domains but were afraid to ask. From 'who is GoDaddy Registry?' to 'what are premium domains?' We're here to help."/>
      </Helmet>
      <WhiteNavBar />
      {timer === true ? (
        <>
          <FaqBanner />
          <FaqQuestionAnswers />
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default Faq;
