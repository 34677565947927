import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import BlackNavBar from "../Partial/BlackNavBar";
import SearchBox from "../Partial/SearchBox";
import HomeFooter from "../Footer/HomeFooter";
import AvailableProgressBar from "./AvailableProgressBar";
import AvailableDomainStatus from "./AvailableDomainStatus";
import Registrar from "./Registrar";
import { getTldInfo } from "../../Services/homeApi";
import Pageloader from "../Partial/Pageloader";

function Available() {
  const [tldId, setTldId] = useState("");
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 300);
  }, []);

  const location = useLocation();
  let domain = "";
  if (location.state !== null) {
    domain = location.state.domain;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.state !== null) {
      let tld_name = domain.substring(domain.indexOf(".") + 1);
      const tldInfoConst = async (tld_name) => {
        let data = await getTldInfo(tld_name);
        setTldId(data[0].id);
      };
      tldInfoConst(tld_name);
      window.dataLayer.push({
        event: "Select domain click",
        page: {
          url: window.location.href,
          title: "HUB",
          tld: tld_name,
        },
      });
    }
  }, []);

  return (
    <>
      {domain ? (
        <>
          <BlackNavBar />
          {timer === true ? (
            <>
              {domain ? <SearchBox domain={domain} /> : ""}
              <AvailableProgressBar />
              {domain ? <AvailableDomainStatus domain={domain} /> : ""}
              {tldId ? <Registrar tld_id={tldId} domain={domain} /> : ""}
              <HomeFooter />
            </>
          ) : (
            <Pageloader />
          )}
        </>
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
}

export default Available;
