import React, { useEffect, useState, useContext } from "react";
import { getAllTlds } from "../../Services/homeApi";
import { getAllCategoryTlds } from "../../Services/categorywisetldApi";
import { getAllCategories } from "../../Services/homeApi";
import { UtmContext } from "../../Context/ContextProvider";
import { Link } from "react-router-dom";

function DomainextensionTldCategory(props) {
  const [tldData, setTldData] = useState([]);
  const [tld, setTld] = useState([]);
  const [tldCategory, setTldCategory] = useState([]);
  const [toggle, setToggle] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const { clickedCategory } = useContext(UtmContext);

  const urlHash = window.location.hash;
  if (urlHash.length) {
    const element = document.getElementById(urlHash.substring(1));
    if (element) {
      element.scrollIntoView();
    }
  }
  useEffect(() => {
    if (clickedCategory[0] === undefined) {
      setTldData(tld);
      setToggle(0);
    } else if (tldCategory.length > 0) {
      const data = tldCategory.filter(
        (data) => data.category_name === clickedCategory[0]
      );
      let filteredData = [];
      data.map((tlData) => {
        return filteredData.push(tlData);
      });

      setTldData(filteredData);
      setToggle(clickedCategory[1]);
    }
  }, [clickedCategory, tldCategory, tld]);

  useEffect(() => {
    const fetchData = async () => {
      let tdata = await getAllTlds();
      let t = [];
      tdata.map((tldata) => {
        return t.push(tldata);
      });
      setTld(t);
    };

    const fetchTldCategory = async () => {
      var tdata = await getAllCategoryTlds();
      setTldCategory(tdata);
    };

    const categoryListData = async () => {
      var categorydata = await getAllCategories();
      setCategoryList(categorydata);
    };

    categoryListData();
    fetchTldCategory();
    fetchData();
  }, []);

  const dataOnClick = (e, categoryName, num) => {
    e.preventDefault();
    const data = tldCategory.filter(
      (data) => data.category_name === categoryName
    );
    let filteredData = [];
    data.map((tlData) => {
      return filteredData.push(tlData);
    });

    setTldData(filteredData);
    setToggle(num);
  };

  return (
    <div className="section-row products-gallery-row" id="productsGallery">
      <div className="container">
        <div className="section-title">
          <h3 className="mb-3 mb-md-0">Browse all products</h3>
        </div>
        <div className="products-main-box">
          <ul className="products-filter d-md-flex flex-wrap">
            <li className={toggle === 0 ? "current" : ""}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setTldData(tld);
                  setToggle(0);
                }}
              >
                Show All
              </a>
            </li>

            {categoryList.map((category, index) => {
              return (
                <li
                  key={index}
                  className={toggle === index + 1 ? "current" : ""}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      dataOnClick(e, category.category_name, index + 1);
                    }}
                  >
                    {category.category_name}
                  </a>
                </li>
              );
            })}
          </ul>

          <div className="productsContainer row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
            {tldData.map((tl, index) => {
              return (
                <div className="col " key={index}>
                  <Link
                    className="products-logo text-center"
                    to={`/${tl.tld_name.toLowerCase()}`}
                  >
                    <h4 className="m-0">
                      <strong>.{tl.tld_name}</strong>
                    </h4>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainextensionTldCategory;
