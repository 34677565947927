import React from "react";
import AvailableDomainList from "./AvailableDomainList";
import SuggestedDomainList from "./SuggestedDomainList";

function TldList({
  domainAvail,
  domain,
  suggestedDomainData,
  exclusiveTld,
  domainName,
}) {
  return (
    <div className="section-row pt-0 mb-3 mb-md-0">
      <div className="container">
        <div className="small-container">
          <div className="tld-tabs">
            <ul className="nav nav-tabs" id="tldTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="SuggestedTld-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#SuggestedTld"
                  type="button"
                  role="tab"
                  aria-controls="SuggestedTld"
                  aria-selected="true"
                >
                  Suggested Names
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="AvailableTld-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#AvailableTld"
                  type="button"
                  role="tab"
                  aria-controls="AvailableTld"
                  aria-selected="false"
                >
                  All Available
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <SuggestedDomainList
                suggestedDomainData={suggestedDomainData}
                exclusiveTld={exclusiveTld}
                domainName={domainName}
              />
              <AvailableDomainList domain={domain} domainAvail={domainAvail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TldList;
