import React from "react";

import "./NotFound.css";
import { Helmet } from "react-helmet";
import HomeFooter from "../Footer/HomeFooter";
import BlackNavBar from "../Partial/BlackNavBar";

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page Cannot Be Found &#124; GoDaddy Registry Domain Hub</title>
        <meta
          name="website"
          content="https://domains.registry.godaddy/page-not-found"
        />
        <meta
          name="description"
          content="The resource requested could not be found on this server. Please search again."
        />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <BlackNavBar />
      <div className="not-found-page">
        <div className="not-found-wrapper">
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>
            we’re sorry. the page you requested could no be found Please go back
            to the home page
          </p>
          <a href="/" className="custom-btn">
            GO HOME
          </a>
        </div>
      </div>

      <HomeFooter />
    </>
  );
}

export default NotFound;
