import React from "react";
import { useEffect, useState } from "react";
import HomeFooter from "../Footer/HomeFooter";
import { getAllCategories, getAllTlds } from "../../Services/homeApi";
import DomainextensionCategory from "./DomainextensionCategory";
import DomainextensionFeatured from "./DomainextensionFeatured";
import DomainextensionPerfectdomain from "./DomainextensionPerfectdomain";
import DomainextensionTldCategory from "./DomainextensionTldCategory";
import DomainextensionTldlist from "./DomainextensionTldlist";
import Pageloader from "../Partial/Pageloader";
import DomainextensionBanner from "./DomainextensionBanner";
import WhiteNavBar from "../Partial/WhiteNavBar";
import {Helmet} from "react-helmet";

function Domainextension(props) {
  const [categoryData, setCategoryData] = useState([]);
  const [tldData, setTldData] = useState([]);
  const [isDataset, setIsDataset] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let data = await getAllCategories();
      let tdata = await getAllTlds();
      setTldData(tdata);
      setCategoryData(data);
      if (data.length > 0 && tdata.length > 0) {
        setIsDataset(true);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
          <title>Find the Right Domain for You &#124; GoDaddy Registry Domain Hub</title> 
          <meta name="website" content="http://domains.registry.godaddy/domainextension"/>
          <meta name="description" content="Search for your perfect domain by browsing through our categories, choosing our 'featured domain', or discover something new for your business."/>
      </Helmet>
      <WhiteNavBar />
      {isDataset === true ? (
        <>
          <DomainextensionBanner />
          <DomainextensionCategory categoryData={categoryData} />
          <DomainextensionFeatured />
          <DomainextensionTldlist tldData={tldData} />
          <DomainextensionTldCategory />
          <DomainextensionPerfectdomain />
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default Domainextension;
