import React from "react";

function WhiteDashLogoBlog() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style={{ enableBackground: "new 0 0 50 50"}} 
      xmlSpace="preserve"
    >
      <path d="M49.9,25.49c0.13-0.31,0.13-0.66,0-0.97c-0.07-0.15-0.16-0.3-0.27-0.42l-8.9-8.9c-0.5-0.5-1.31-0.5-1.8,0c-0.49,0.5-0.5,1.31,0,1.8l6.73,6.73H1.27C0.57,23.73,0,24.3,0,25.01s0.57,1.27,1.27,1.27h44.39L38.93,33c-0.5,0.5-0.5,1.31,0,1.8c0.25,0.25,0.58,0.37,0.9,0.37c0.32,0,0.65-0.13,0.9-0.37l8.9-8.9C49.74,25.78,49.84,25.65,49.9,25.49z" />
    </svg>
  );
}

export default WhiteDashLogoBlog;
