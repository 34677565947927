import React from "react";

function NavArrowLogo(props) {
  return (
    <span className="arrow-ico">
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.967742 10.5066L16.6885 10.5066L10.591 16.7972C10.4101 16.9845 10.3086 17.2383 10.3089 17.5027C10.3091 17.7671 10.4111 18.0206 10.5923 18.2076C10.7735 18.3946 11.0193 18.4997 11.2756 18.5C11.5319 18.5003 11.7778 18.3956 11.9594 18.209L19.6933 10.2302C19.7896 10.138 19.8665 10.0263 19.9193 9.90206C19.9721 9.77785 19.9995 9.64379 20 9.50817L19.9992 9.50427L20 9.50037C19.9999 9.23561 19.8979 8.9817 19.7165 8.79447L11.9594 0.792393C11.8696 0.699694 11.7629 0.62616 11.6455 0.575991C11.5281 0.525822 11.4023 0.500001 11.2752 0.500001C11.1481 0.500001 11.0223 0.525822 10.9049 0.575991C10.7875 0.62616 10.6808 0.699694 10.591 0.792394C10.5011 0.885095 10.4298 0.995145 10.3812 1.11626C10.3326 1.23738 10.3076 1.36719 10.3076 1.49829C10.3076 1.62939 10.3326 1.7592 10.3812 1.88032C10.4298 2.00144 10.5011 2.11149 10.591 2.20418L16.7035 8.50978L0.967742 8.50978C0.71108 8.50978 0.464931 8.61497 0.283445 8.80221C0.101956 8.98944 -8.09239e-07 9.24339 -7.8609e-07 9.50818C-7.62942e-07 9.77297 0.101957 10.0269 0.283445 10.2141C0.464931 10.4014 0.71108 10.5066 0.967742 10.5066Z"
          fill="#111111"
        />
      </svg>
    </span>
  );
}

export default NavArrowLogo;
