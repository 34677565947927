import React from "react";

function AboutBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-banner d-flex align-items-end align-items-md-center">
          <div
            className="banner-img d-none d-md-block"
            style={{
              backgroundImage: `url(/images/aboutUs_bannner.jpg)`,
            }}
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
              backgroundImage: `url(/images/aboutUs_bannner.jpg)`,
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="hero-text page-title-text text-white pb-md-4">
                  <h1 className="text-white mb-0 mb-md-5 pb-0">About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutBanner;
