import React,{useEffect} from "react";
import GoDaddyLogo from "../Logos/GoDaddyLogo";
import NavArrowLogo from "../Logos/NavArrowLogo";

function Navigation({language}) {
  let menuBar;
  if (language === "spanish") {
    menuBar = require(`../../Constants/menuDataspanish.js`);
  }else if (language === "german") {
    menuBar = require(`../../Constants/menuDatagerman.js`);
  } else {
    menuBar = require(`../../Constants/menuData.js`);
  }

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({ pageLanguage: 'en',layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element')
   }
   
   useEffect(() => {
     var addScript = document.createElement('script');
     addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
     document.body.appendChild(addScript);
     window.googleTranslateElementInit = googleTranslateElementInit;
   }, [])

  return (
    <header id="header">
      <div className="container">
        <div className="d-flex align-items-center">
          <div className="logo">
            <a href="/" aria-label="Hub">
              <GoDaddyLogo />
            </a>
          </div>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#headerMenu"
            aria-controls="headerMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu-lines"></span>
          </button>
          
          <div className="header-menu">
            <div className="collapse navbar-collapse" id="headerMenu">
              <ul className="navbar-nav">
                {menuBar.default.map((menuBar, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <a
                        className="nav-link active"
                        aria-current="page"
                        title={menuBar[0]}
                        href={"/" + menuBar[1]}
                        onClick={(e) => {
                          if (menuBar[1] === "") {
                            e.preventDefault();
                          }
                        }}
                      >
                        {menuBar[0]}
                        <NavArrowLogo />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="google_translate_box">
              <div id="google_translate_element"></div>
            </div>
        </div>
      </div>
    </header>
  );
}

export default Navigation;
