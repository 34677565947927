import React from "react";

function WhatisDomain(tldInfo ,tld_name) {
  const tld_backgroundColor =
    tldInfo["tldInfo"]["tld_section"].tld_backgroundColor;
  const tld_text_color = tldInfo["tldInfo"]["tld_section"].tld_text_color;
  return (
    <>
      <div className="section-row spot-feature-row">
        <div className="container">
          <div
            className="spot-feature-box"
            style={{
              background: tld_backgroundColor,
              color: tld_text_color,
            }}
          >
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="spot-feature-img">
                  <figure>
                    <img
                      src={
                        tldInfo["tldInfo"]["tld_section"].tld_brand_logo === ""
                          ? "/images/godaddy-registry-placeholder.jpg"
                          : "/images/" +
                            tldInfo["tldInfo"]["tld_section"].tld_brand_logo
                      }
                      alt={tldInfo["tld_name"] + " Logo"}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-md-7">
                <div className="spot-feature-details">
                  <h4>{tldInfo["tldInfo"]["tld_section"].heading}</h4>
                  <div className="font-xl">
                    <p>{tldInfo["tldInfo"]["tld_section"].description}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          tldInfo["tldInfo"]["tld_section"].learn_more_link,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatisDomain;
