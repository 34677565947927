import React from "react";
import GetitWhite from "../../Partial/GetitWhite";

function AvailableDomainList({ domain, domainAvail }) {
  return (
    <div
      className="tab-pane fade"
      id="AvailableTld"
      role="tabpanel"
      aria-labelledby="AvailableTld-tab"
    >
      <div className="tld-tabs-list">
        {domainAvail.map((domain, index) => {
          return domain.data.status === "Available" ? (
            <div className="tld-tabs-box" key={index}>
              <div className="tld-tabs-title-box d-sm-flex align-items-center">
                <div className="tld-name-tabs-box d-sm-flex align-items-center flex-row-reverse me-auto">
                  <h4 className="my-2 me-sm-3">
                    {domain.data.domain.split(".")[0]}
                    <strong>
                      .
                      {domain.data.domain.substring(
                        domain.data.domain.indexOf(".") + 1
                      )}
                    </strong>
                  </h4>
                </div>
                <GetitWhite actualDomain={domain.data.domain.toLowerCase()} />
              </div>
            </div>
          ) : (
            <div key={index}></div>
          );
        })}
      </div>
    </div>
  );
}

export default AvailableDomainList;
