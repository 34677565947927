import React, { useState } from "react";
import SearchIcon from "../Logos/SearchIcon";
import {
  validateNoCharacter,
  validateBlank,
  validSearch,
} from "../../Services/validations";
import ArrowDashLogo from "../Logos/ArrowDashLogo";
import regex from "../../Constants/regValidation";

function SearchBox({ domain }) {
  const [searchedText, setSearchedText] = useState("");

  return (
    <>
      <div className="py-md-3 py-4">
        <div className="container">
          <div className="small-container">
            <div className="tld-search-form-box">
              <div className="tld-search">
                <form
                  action="/search"
                  method="get"
                  onSubmit={(e) => validSearch(searchedText, e)}
                >
                  <div className="tld-search-form">
                    <span className="search-ico">
                      <SearchIcon />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="YourDomain.tld"
                      name="domain"
                      defaultValue={domain}
                      onKeyPress={(e) => validateNoCharacter(e)}
                      onChange={(e) => {
                        let text = e.target.value;
                        if (regex.test(text)) {
                          setSearchedText("");
                        } else {
                          setSearchedText(text);
                        }
                      }}
                    />
                    <button
                      type="submit"
                      className="search-btn btn btn-primary"
                    >
                      <span className="search-ico">
                        <SearchIcon />
                      </span>
                      <span>
                        Search Domains <ArrowDashLogo />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchBox;
