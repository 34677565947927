import React from "react";

function AccreditedRegistrarsBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-banner d-flex align-items-end align-items-md-center">
          <div
            className="banner-img"
            style={{
                backgroundImage: `url(./images/accredited-registrars-desktop.jpg)`, 
              }}   
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
                backgroundImage: `url(./images/accredited-registrars-mobile.jpg)`, 
              }} 
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="hero-text page-title-text text-white pb-md-4 text-center">
                  <h1 className="text-white mb-0 mb-md-3 pb-0">
                    Accredited Registrars
                  </h1>
                  <p className="mt-2 m-0 font-xl">
                    {/* Lorem ipsum dolor sit amet, consectetur adipisicing. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccreditedRegistrarsBanner;
