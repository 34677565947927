import React from "react";

function PoliciesContainer() {
  return (
    <>
      <div className="section-row">
        <div className="container">
          <div className="section-title border-bottom mb-md-4">
            <h3 className="mb-3">General Policies</h3>
          </div>
          <div className="policy-accordion">
            <div className="row gap-3 g-md-4 accordion" id="policyAccordion1">
              <div className="col-md-6">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Registry-DomainsOne"
                      aria-expanded="false"
                      aria-controls="Registry-DomainsOne"
                    >
                      GoDaddy Registry
                    </button>
                  </h2>
                  <div
                    id="Registry-DomainsOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#policyAccordion1"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              href="policiespdf/GoDaddy_Registry_Universal/GDR-DNSSEC_Practice_Statement-GoDaddy_Registry-1.2.pdf"
                              target="_blank"
                            >
                              DNSSEC Practice Statement
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/GoDaddy_Registry_Universal/GDR-POL-001-RegistrationPolicy-1.0.pdf"
                            >
                              Registration Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/GoDaddy_Registry_Universal/GDR-POL-002-AcceptableUsePolicy-1.1.pdf"
                            >
                              Acceptable Use Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/GoDaddy_Registry_Universal/GDR-POL-003-RegistryOperationsPolicy-1.0.pdf"
                            >
                              Registry Operations Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-row border-top">
        <div className="container">
          <div className="section-title border-bottom mb-md-4">
            <h3 className="mb-3">Individual Policies</h3>
          </div>
          <div className="policy-accordion">
            <div className="row accordion" id="policyAccordion2">
              {/* abogado */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#abogado-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesOne"
                    >
                      .abogado
                    </button>
                  </h2>
                  <div
                    id="abogado-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesOne"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/ABGD-POL-001-Eligibility_Policy-1.0.pdf"
                            >
                              Eligibility Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* bayern */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#bayern-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesTwo"
                    >
                      .bayern
                    </button>
                  </h2>
                  <div
                    id="bayern-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesTwo"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/BYRN-POL-001-Eligibility_Policy-1.0.pdf"
                            >
                              Eligibility Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* biz */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#biz-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesThree"
                    >
                      .biz
                    </button>
                  </h2>
                  <div
                    id="biz-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesThree"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/BIZ-POL-004-Rapid_Takedown_Process-1.0.pdf"
                            >
                              Rapid Takedown Process
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* CO */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#co-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesFour"
                    >
                      .CO
                    </button>
                  </h2>
                  <div
                    id="co-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesFour"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.go.co/company/domain-mgmt"
                            >
                              View all policies here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* gay */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="IndividualPoliciestwelve"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#gay-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciestwelve"
                    >
                      .gay
                    </button>
                  </h2>
                  <div
                    id="gay-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciestwelve"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/GAY-POL-001-Community_Protection_Policy-0.pdf"
                            >
                              Community Protection Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/Year+3_2022_GayImpactReport.pdf"
                            >
                              3 Yr .gay Annual Impact report
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* law */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#law-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesFive"
                    >
                      .law
                    </button>
                  </h2>
                  <div
                    id="law-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesFive"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/LAW-POL-001-Eligibility_Policy-1.0.pdf"
                            >
                              Eligibility Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* melbourne */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#melbourne-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesSix"
                    >
                      .melbourne
                    </button>
                  </h2>
                  <div
                    id="melbourne-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesSix"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ol type="1">
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-COP-001-Registrar_Code_of_Practice-2.2.pdf"
                            >
                              Registrar Code of Practice
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-LAU-001-Launch_Policy-2.1.pdf"
                            >
                              Launch Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-POL-001-Registration_Policy-2.3.pdf"
                            >
                              Registration Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-POL-002-Acceptable_Use_Policy-2.2.pdf"
                            >
                              Acceptable Use Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-POL-003-Compliance_Policy-2.3.pdf"
                            >
                              Compliance Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-POL-004-Domain_Name_Lifecycle_Policy-2.1.pdf"
                            >
                              Domain Name Lifecycle Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-POL-005-WHOIS_Policy-2.2.pdf"
                            >
                              WHOIS Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-POL-006-Zone_File_Access_Policy-2.1.pdf"
                            >
                              Zone File Access Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-DRP-003-NationalArbitrationForum-RegistrationEligibilityDisputeResolutionPolicy-2014-07-10.pdf"
                            >
                              National Arbitration Forum - Registration
                              Eligibility Dispute Resolution Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/MEL-Policies/MEL-DRP-004-NationalArbitrationForum-RegistrationEligibilityDisputeResolutionPolicyRules-2014-07-10.pdf"
                            >
                              National Arbitration Forum - Registration
                              Eligibility Dispute Resolution Policy Rules
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* nrw */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#nrw-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesSeven"
                    >
                      .nrw
                    </button>
                  </h2>
                  <div
                    id="nrw-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesSeven"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/NRW-POL-001-Eligibility_Policy-1.0.pdf"
                            >
                              Eligibility Policy
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* nyc */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#nyc-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesEight"
                    >
                      .nyc
                    </button>
                  </h2>
                  <div
                    id="nyc-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesEight"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.ownit.nyc/policies"
                            >
                              View all policies here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* sydney */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesNine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#sydney-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesNine"
                    >
                      .sydney
                    </button>
                  </h2>
                  <div
                    id="sydney-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesNine"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ol type="1">
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-COP-001-Registrar_Code_of_Practice-1.1.pdf"
                            >
                              Registrar Code of Practice
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-LAU-001-Launch_Policy-1.1.pdf"
                            >
                              Launch Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-POL-001-Registration_Policy-1.3.pdf"
                            >
                              Registration Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-POL-002-Acceptable_Use_Policy-1.2.pdf"
                            >
                              Acceptable Use Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-POL-003-Compliance_Policy-1.2.pdf"
                            >
                              Compliance Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-POL-004-Domain_Name_Lifecycle_Policy-1.1.pdf"
                            >
                              Domain Name Lifecycle Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-POL-005-WHOIS_Policy-1.1.pdf"
                            >
                              WHOIS Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-POL-006-Zone_File_Access_Policy-1.1.pdf"
                            >
                              Zone File Access Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-DRP-003-NationalArbitrationForum-RegistrationEligibilityDisputeResolutionPolicy-1.0-2014-11-10.pdf"
                            >
                              National Arbitration Forum - Registration
                              Eligibility Dispute Resolution Policy
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="policiespdf/SYD-Policies/SYD-DRP-004-NationalArbitrationForum-RegistrationEligibilityDisputeResolutionPolicyRules-1.0-2014-11-10.pdf"
                            >
                              National Arbitration Forum - Registration
                              Eligibility Dispute Resolution Policy Rules
                            </a>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* US */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="IndividualPoliciesEleven"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#us-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesEleven"
                    >
                      .US
                    </button>
                  </h2>
                  <div
                    id="us-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesEleven"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.about.us/policies"
                            >
                              View all policies here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* VU */}
              <div className="col-md-6 my-2 my-md-3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="IndividualPoliciesTen">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#vu-Policies"
                      aria-expanded="false"
                      aria-controls="IndividualPoliciesTen"
                    >
                      .VU
                    </button>
                  </h2>
                  <div
                    id="vu-Policies"
                    className="accordion-collapse collapse"
                    aria-labelledby="IndividualPoliciesTen"
                    data-bs-parent="#policyAccordion2"
                  >
                    <div className="accordion-body font-l">
                      <div className="policy-list">
                        <ul>
                          <li>
                            <a target="_blank" href="https://www.hello.vu/">
                              View all policies here
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoliciesContainer;
