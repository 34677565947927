import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import WhiteDashLogoBlog from "../Logos/WhiteDashLogoBlog";

function BlogSingle({ blogData }) {
  const [tstring, setTstring] = useState("");
  useEffect(() => {
    let tagString = "";
    blogData.tags.map((tags, index) => {
      index + 1 === blogData.tags.length
        ? (tagString = tagString + tags.tag)
        : (tagString = tagString + tags.tag + ", ");
    });
    setTstring(tagString);
  }, []);

  return (
    <>
      <div className="single-post-box">
        <div className="post-slider-box d-flex bg-theme">
          <div className="post-slider-content mt-auto">
            <h4>
              <a href={"/blog/" + blogData.friendly_url}>{blogData.title}</a>
            </h4>
            <span>
              <a href={"/blog/" + blogData.friendly_url}>
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: tstring,
                    }}
                  ></span>
                }
              </a>
            </span>
            <a
              href={"/blog/" + blogData.friendly_url}
              className="post-slider-link"
            >
              <WhiteDashLogoBlog />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogSingle;
