import React, { useEffect,  useState } from "react";
import WhiteNavBar from "../Partial/WhiteNavBar";
import PurchaseDomain from "./PurchaseDomain";
import PerfectDomain from "./PerfectDomain";
import HomeFooter from "../Footer/HomeFooter";
import Spotlight from "./Spotlight";
import CategoryBox from "../Categories/CategoryBox";
import TldsMenu from "./TldsMenu";
//import { UtmContext } from "../../Context/ContextProvider";
import { getAllTlds } from "../../Services/homeApi";
import Pageloader from "../Partial/Pageloader";
import {Helmet} from "react-helmet";


function Home(props) {

  const [tldData, setTldData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      var tdata = await getAllTlds();
      setTldData(tdata);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>      
      <Helmet>
          <title>Find Your Perfect Domain &#124; GoDaddy Registry Domain Hub</title> 
          <meta name="website" content="http://domains.registry.godaddy/"/>
          <meta name="description" content="The GoDaddy Registry Domain Hub has over 35 top-level domains to choose from so you can find the right domain name for your business, brand, or big idea."/>
      </Helmet>
      <WhiteNavBar />
      {tldData.length > 0 ? <TldsMenu tldData={tldData} /> : <Pageloader />}
      <CategoryBox />
      <Spotlight />
      <PurchaseDomain />
      <PerfectDomain />
      <HomeFooter />
    </>
  );
}

export default Home;
