export const duplicateBlogs = {
  "the-state-of-women-entrepreneurship-in-america-ongoing-challenges-and-how-to-overcome-them":
    "https://www.about.us/blog/the-state-of-women-entrepreneurship-in-america-ongoing-challenges-and-how-to-overcome-them",
  "how-kay-koplovitz-cable-industry-pioneer-and-visionary-brands-her-passion-for-propelling-women-in-tech":
    "https://blog.go.co/2023/03/07/how-kay-koplovitz-cable-industry-pioneer-and-visionary-brands-her-passion-for-propelling-women-in-tech",
  "top-10-statistics-about-women-entrepreneurs-that-will-inspire-you":
    "top-10-statistics-about-women-entrepreneurs-that-will-inspire-youhttps://blog.go.co/2021/03/02/top-10-statistics-about-women-entrepreneurs-that-will-inspire-you",
  "dropshipping-what-is-it-and-what-does-it-consist-of":
    "https://www.cointernet.com.co/blog/dropshipping-que-es-y-en-que-consiste",
  "how-to-brand-your-creative-work-online-with-alexrobinson-tv":
    "https://turnon.tv/whosontv/alexrobinson.tv",
  "how-grab-a-slice-pays-homage-to-nyc-culture-through-music-fashion-and-pizza":
    "https://www.ownit.nyc/posts/how-grab-a-slice-pays-homage-to-nyc-culture-through-music-fashion-and-pizza",
  "why-the-bean-nyc-is-the-coffee-shop-you-want-in-your-neighborhood":
    "https://www.ownit.nyc/posts/why-the-bean-nyc-is-the-coffee-shop-you-want-in-your-neighborhood",
  "how-caroline-shin-went-from-columbia-journalism-to-cooking-with-granny":
    "https://turnon.tv/whosontv/cookingwithgranny.tv",
  "why-a-motorcycle-journalist-launched-roaddirt-tv":
    "https://turnon.tv/whosontv/roaddirt.tv",
    "create-an-unforgettable-wedding-website-with-the-perfect-domain-a-guide-to-selecting-and-building-your-dream-site":"https://www.about.us/blog/a-wedding-domain-your-family-and-friends-can-remember",
    "why-americans-love-small-businesses-now-more-than-ever":"https://www.about.us/blog/why-americans-love-small-businesses-now-more-than-ever"
};
