import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import HomeFooter from "../Footer/HomeFooter";
import WhiteNavBar from "../Partial/WhiteNavBar";
import BannerTldDetailTemplate from "./BannerTldDetailTemplate";
import SearchSectionDetailTemplate from "./SearchSectionDetailTemplate";
import WhatisDomain from "./WhatisDomain";
import WhosUsing from "./WhosUsing";
import WhyUs from "./WhyUs";
import { getAllTlds } from "../../Services/homeApi";
import pageLanguage from "../../Constants/pageLanguage";
import Pageloader from "../Partial/Pageloader";
import { Helmet } from "react-helmet";

function TldDetailTemplate() {
  const urlParams = useParams();
  const tld_name = urlParams.tld_name;
  const language = urlParams.language;
  const [tldData, setTldData] = useState([]);
  const [availabletld, setAvailabletld] = useState(false);
  const [availabletested, setAvailabletested] = useState("");
  const [tldInfo, setTldInfo] = useState({});
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 600);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const fetchData = async () => {
      var tdata = await getAllTlds();
      setTldData(tdata);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let t = false;
    let tldIn = {};
    tldData.map((tld) => {
      if (tld.tld_name.toLowerCase() === tld_name.toLowerCase()) {
        if (typeof language !== "undefined") {
          pageLanguage.map((pageLanguage) => {
            if (pageLanguage[0] === tld_name && language === pageLanguage[1]) {
              t = true;
              tldIn = require(`../../Constants/tld-details/${tld_name.toLowerCase()}/other-language-config.json`);
            }
            return t;
          });
        } else {
          t = true;
          tldIn = require(`../../Constants/tld-details/${tld_name.toLowerCase()}/config.json`);
        }
      }
      setAvailabletld(t);
      setTldInfo(tldIn);
      setAvailabletested("1");
      return t;
    });
  }, [tldData, tld_name]);

  return (
    <>
      {availabletested !== "" ? (
        availabletld ? (
          <>
            <WhiteNavBar language={language} />
            {timer === true ? (
              <>
                {tldInfo.meta_tags ? (
                  <Helmet>
                    <title>{tldInfo.meta_tags.title}</title>
                    <meta name="website" content={tldInfo.meta_tags.website} />
                    <meta
                      name="description"
                      content={tldInfo.meta_tags.description}
                    />
                    <meta property="og:type" content="website" />
                    <meta
                      property="og:url"
                      content={tldInfo.meta_tags.website}
                    />
                    <meta
                      property="og:title"
                      content={tldInfo.meta_tags.title}
                    />
                    <meta
                      property="og:description"
                      content={tldInfo.meta_tags.description}
                    />
                    <meta
                      name="og:image"
                      content={
                        tldInfo["header_section"].bgimage
                          ? process.env.REACT_APP_TLD_IMAGE_URL +
                            tldInfo["header_section"].bgimage
                          : "/images/tld-details-page-img/" +
                            tldInfo["header_section"].folder_bgimage
                      }
                    />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@GoDaddyRegistry" />
                    <meta
                      name="twitter:title"
                      content={tldInfo.meta_tags.title}
                    />
                    <meta
                      name="twitter:description"
                      content={tldInfo.meta_tags.description}
                    />
                    <meta
                      name="twitter:image"
                      content={
                        tldInfo["header_section"].bgimage
                          ? process.env.REACT_APP_TLD_IMAGE_URL +
                            tldInfo["header_section"].bgimage
                          : "/images/tld-details-page-img/" +
                            tldInfo["header_section"].folder_bgimage
                      }
                    />
                  </Helmet>
                ) : (
                  ""
                )}
                <BannerTldDetailTemplate tldInfo={tldInfo} tldName={tld_name} />
                <WhatisDomain tldInfo={tldInfo} tld_name={tld_name} />
                <WhyUs tldInfo={tldInfo} />
                <WhosUsing tldInfo={tldInfo} />
                <SearchSectionDetailTemplate
                  tldInfo={tldInfo}
                  tldName={tld_name}
                />
                <HomeFooter language={language} />
              </>
            ) : (
              <Pageloader />
            )}
          </>
        ) : (
          <Navigate to={"/page-not-found"} />
        )
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default TldDetailTemplate;
