import React from "react";
import "../../App.css";

function SearchIcon(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style={{ enableBackground: "new 0 0 50 50" }}
      xmlSpace="preserve"
    >
      <path d="M50,46.57L33.33,29.9c2.39-3.13,3.83-7.02,3.83-11.25c0-10.24-8.33-18.58-18.58-18.58S0,8.4,0,18.65c0,10.24,8.33,18.58,18.58,18.58c4.3,0,8.26-1.48,11.41-3.95l16.65,16.65L50,46.57z M4.75,18.65c0-7.63,6.2-13.83,13.83-13.83s13.83,6.2,13.83,13.83s-6.2,13.83-13.83,13.83S4.75,26.27,4.75,18.65z" />
    </svg>
  );
}

export default SearchIcon;
