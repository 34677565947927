import React from "react";

const FeaturedDomain = (props) => {
  return (
    <div className="premium-ai-featureddomain">
      <div className="contact-map-frame pre-aipage">
        <div class="row kay-details">
          <div class="col-md-3 Kathy-img">
            <img
              src="/images/Kathy_Nielsen.jpeg"
              class="img-fluid"
              alt="Responsive image"
            ></img>
          </div>
          <div class="col-md-9 Kathy-info ">
            <p>
              <b>Kathy Nielsen</b>
              <br></br>
              <b>
                <i>Director of Premium Names</i>
              </b>
            </p>
            <p>
              <b>Currently featuring:</b>
              <br></br>
              <b>- ai.club</b>
              <br></br>
              <b>- ai.biz</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedDomain;
