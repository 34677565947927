const fmenu = [
  ["Akkreditierte Registrare", "accreditedregistrars", ""],
  ["Rechtliches", "https://registry.godaddy/legal", "_blank"],
  ["Datenschutz", "https://registry.godaddy/legal/privacy-policy", "_blank"],
  ["WHOIS", "https://whois.dnrs.godaddy/", "_blank"],
  ["Richtlinien", "policies", ""],
  [
    "Präferenzen für Werbung",
    "http://preferences-mgr.truste.com/?pid=godaddy01&aid=godaddy01&type=godaddy",
    "_blank",
  ],
];
export default fmenu;
