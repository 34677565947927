import React from "react";

import TldDetailSearchbox from "./TldDetailSearchbox";

function SearchSectionDetailTemplate(props) {
  const tld_backgroundColor = props.tldInfo["footer_search_section"].tld_backgroundColor;
  const tld_text_color = props.tldInfo["footer_search_section"].tld_text_color;
  const tld_search_placeholder =  props.tldInfo["header_section"].tld_search_placeholder;
  const searchbox_button_text =  props.tldInfo["header_section"].searchbox_button_text;
  
  return (
    <>
      <div
        className="section-row bg-theme tld-search-bottom-row"        
        style={{
          background: tld_backgroundColor,
          color: tld_text_color,
        }}  
      >
        <div className="section-row">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="tld-search-bottom text-center">
                  <h2 className="mb-4">
                    {props.tldInfo["footer_search_section"].heading}
                  </h2>
                  <div className="tld-search">
                    <TldDetailSearchbox tldName={props.tldName} tld_search_placeholder={tld_search_placeholder} searchbox_button_text={searchbox_button_text}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchSectionDetailTemplate;
