import React from "react";

function Pageloader(props) {
  return (
    <div className="page-loader">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Pageloader;
