import React from "react";
import ArrowDashLogo from "../Logos/ArrowDashLogo";

function AboutWhySection() {
  return (
    <>
      <div className="section-row">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-5">
              <figure>
                <img
                  src={"/images/img-main_marquee-dot_co-desktop.jpg"}
                  alt=""
                />
              </figure>
            </div>
            <div className="col-md-6">
              <h4>
                <strong>GoDaddy Registry</strong>
              </h4>
              <p>
                A subsidiary of GoDaddy, Inc., GoDaddy Registry is a
                world-leading domain name registry.
              </p>

              <p>
                {" "}
                We provide the technical, operational, and security
                infrastructure that powers the resolution and resilience of one
                of the world’s largest and most diverse portfolios of top-level
                domain extensions, including .CO, .US, .biz, .club, .design,
                .nyc, and hundreds more.
              </p>

              <p>
                But managing critical internet infrastructure is just scratching
                the surface.
              </p>
              <p>
                We provide new and exciting digital naming options so you can
                find the perfect domain name for your business, brand, or big
                idea without ever having to compromise.
              </p>
              <p>
                We believe that your domain name should be a platform of
                possibility:
              </p>

              <ul>
                <li>
                  a place to manifest your dreams, or create a bold new future.
                </li>
                <li>
                  a way to inspire your fans and followers or start a fan club.
                </li>
                <li>
                  a means to connect and communicate with the people and things
                  you love.
                </li>
              </ul>
              <p>
                Whatever it is you hope to do, achieve, create, or build online,
                we invite you to check out our exciting selection of domain
                extensions to find the domain name that’s just right for you.
              </p>
              <a href="/domainextension" className="btn btn-primary">
                <span>
                  Domain Extensions
                  <ArrowDashLogo />
                </span>
              </a>
              <p></p>
              <p>
                <small>
                  For more about GoDaddy Registry’s back-end technical services
                  and capabilities,{" "}
                  <a
                    href="https://registry.godaddy"
                    className="text-dark"
                    target="_blank"
                  >
                    visit registry.godaddy
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutWhySection;
