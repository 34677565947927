import React from "react";

function PremiumAiBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-banner d-flex align-items-end align-items-md-center">
          <div
            className="banner-img premium-banner d-none d-md-block"
            style={{
              backgroundImage: "url('/images/shutterstock_2250866885.jpg')",
            }}
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
              backgroundImage: `url('/images/shutterstock_2250866885.jpg')`,
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="hero-text page-title-text text-white pb-md-4 text-center">
                  <h1 className="text-white mb-0 mb-md-3 pb-0 mx-auto">
                    Premium Domains
                  </h1>
                  <p className="mt-2 m-0 font-xl">
                    Seize this opportunity now before it slips away – time is of
                    the essence!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PremiumAiBanner;
