import React from "react";
import HubSearchBox from "../Partial/HubSearchBox";

function AboutFooterSection() {
  return (
    <>
      <div className="section-row bg-theme tld-search-bottom-row">
        <div className="section-row">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 ">
                <div className="tld-search-bottom text-center">
                  <h2 className="mb-4">
                    A domain name just for you — one that tells your story.
                  </h2>
                  <div className="tld-search">
                    <HubSearchBox url = {window.location.pathname}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutFooterSection;
