import React from "react";

function BottomFooter({ language }) {
  let fmenu;
  if (language === "spanish") {
    fmenu = require(`../../Constants/fmenuspanish.js`);
  } else if (language === "german") {
    fmenu = require(`../../Constants/fmenugerman.js`);
  } else {
    fmenu = require(`../../Constants/fmenu`);
  }
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="footer-bottom-box">
          <div className="copyright-main-box d-none d-md-block">
            <div className="copyright-box">
              <p>
                {language === "spanish"
                  ? `Copyright ©${new Date().getFullYear()} Registry Services, LLC. Todos los derechos reservados. La marca denominativa GoDaddy es una marca registrada de GoDaddy Operating Company, LLC en EE.UU. y otros países. El logotipo “GO” es una marca registrada de GoDaddy.com, LLC en los EE.UU.`
                  : language === "german"
                  ? `Urheberrecht ©${new Date().getFullYear()} Registry Services, LLC. Alle Rechte vorbehalten. Die GoDaddy-Wortmarke ist eine eingetragene Marke der GoDaddy Operating Company, LLC in den USA und anderen Ländern. Das "GO"-Logo ist ein eingetragenes Warenzeichen von GoDaddy.com, LLC in den USA.`
                  : `Copyright ©${new Date().getFullYear()} Registry Services, LLC. All Rights Reserved. The GoDaddy word mark is a registered trademark of GoDaddy Operating Company, LLC in the US and other countries. The “GO” logo is a registered trademark of GoDaddy.com, LLC in the US.`}
              </p>
            </div>
          </div>
          <div className="fmenu-main-box">
            <div className="fmenu-box">
              <ul>
                {fmenu.default.map((menuItem, index) => {
                  return (
                    <li key={index}>
                      {menuItem[2] === "" ? (
                        <a
                          href={"/" + menuItem[1]}
                          title={menuItem[0]}
                          target={menuItem[2]}
                          rel="noopener"
                          onClick={(e) => {
                            if (menuItem[1] === "") {
                              e.preventDefault();
                            }
                          }}
                        >
                          {menuItem[0]}
                        </a>
                      ) : (
                        <a
                          href={menuItem[1]}
                          title={menuItem[0]}
                          target={menuItem[2]}
                          rel="noopener"
                          onClick={(e) => {
                            if (menuItem[1] === "") {
                              e.preventDefault();
                            }
                          }}
                        >
                          {menuItem[0]}
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="personal-information d-md-none">
            <div className="personal-information-box">
              <p>
                <a
                  href="https://registry.godaddy/legal/cookie-policy"
                  target={"_blank"}
                >
                  {language === "spanish"
                    ? "No vender mis datos personales"
                    : language === "german"
                    ? "Verkaufen Sie meine persönlichen Informationen nicht"
                    : "Do not sell my personal information"}
                </a>
              </p>
            </div>
          </div>
          <div className="copyright-main-box d-md-none">
            <div className="copyright-box">
              <p>
                {language === "spanish"
                  ? `Copyright ©${new Date().getFullYear()} Registry Services, LLC. Todos los derechos reservados. La marca denominativa GoDaddy es una marca registrada de GoDaddy Operating Company, LLC en EE.UU. y otros países. El logotipo “GO” es una marca registrada de GoDaddy.com, LLC en los EE.UU.`
                  : language === "german"
                  ? `Urheberrecht ©${new Date().getFullYear()} Registry Services, LLC. Alle Rechte vorbehalten. Die GoDaddy-Wortmarke ist eine eingetragene Marke der GoDaddy Operating Company, LLC in den USA und anderen Ländern. Das "GO"-Logo ist ein eingetragenes Warenzeichen von GoDaddy.com, LLC in den USA.`
                  : `Copyright ©${new Date().getFullYear()} Registry Services, LLC. All Rights Reserved. The GoDaddy word mark is a registered trademark of GoDaddy Operating Company, LLC in the US and other countries. The “GO” logo is a registered trademark of GoDaddy.com, LLC in the US.`}
              </p>
            </div>
          </div>
          <div className="fnote-box-main-box">
            <div className="fnote-box">
              <p>
                {language === "spanish"
                  ? "El uso de este sitio está sujeto a las condiciones de uso expresas. Al utilizar este sitio, usted acepta someterse a estos"
                  : language === "german"
                  ? "Die Nutzung dieser Website unterliegt den ausdrücklichen Nutzungsbedingungen. Durch die Nutzung dieser Website erklären Sie sich mit diesen allgemeinen"
                  : "Use of this Site is subject to express terms of use. By using this site, you signify that you agree to be bound by these"}
                &nbsp;
                <a href="https://registry.godaddy/legal" target={"_blank"}>
                  {language === "spanish"
                    ? "Términos Universales de Servicio."
                    : language === "german"
                    ? "Nutzungsbedingungen einverstanden."
                    : "Universal Terms of Service."}
                </a>
              </p>
            </div>
          </div>
          <div className="personal-information d-none d-md-block">
            <div className="personal-information-box">
              <p>
                <a
                  href="https://registry.godaddy/legal/cookie-policy"
                  target={"_blank"}
                >
                  {language === "spanish"
                    ? "No vender mis datos personales"
                    : language === "german"
                    ? "Verkaufen Sie meine persönlichen Informationen nicht"
                    : "Do not sell my personal information"}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomFooter;
