import React, { useState, useEffect } from "react";
import { getAllTlds } from "../../Services/homeApi";
import Splide from "@splidejs/splide";
import "@splidejs/react-splide/css";
import GetitDE from "../Partial/GetitDE";
import { Link } from "react-router-dom";

function DomainextensionTldlist({ tldData }) {
  const tldList = tldData.map((tld, index) => {
    return (
      <li className="splide__slide" key={index}>
        <div className="discover-tld-box">
          <div
            className="banner-img d-none d-md-block"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${tld.tld_bgimage})`,
            }}
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${tld.tld_bgimage_mobile})`,
            }}
          ></div>
          <div className="discover-tld-info">
            <h4 className="text-white">.{tld.tld_name}</h4>

            <Link
              to={`/${tld.tld_name.toLowerCase()}`}
              className="btn btn-xs btn-secondary"
            >
              <GetitDE />
            </Link>
          </div>
        </div>
      </li>
    );
  });
  useEffect(() => {
    let splide2 = new Splide(".discover-tld-slider", {
      type: "loop",
      perPage: 4,
      perMove: 1,
      padding: "0",
      cover: true,
      lazyLoad: "nearby",
      gap: "1rem",
      pagination: false,
      autoplay: true,
      interval: 4000,

      breakpoints: {
        0: {
          perPage: 1,
        },
        575: {
          perPage: 1,
        },
        767: {
          perPage: 2,
        },
        991: {
          perPage: 3,
        },
        1024: {
          perPage: 4,
        },
      },
    });
    splide2.mount();
  }, [tldData]);

  return (
    <div className="section-row discover-row">
      <div className="container position-relative">
        <div className="section-title border-bottom">
          <h3 className="mb-3">Discover New TLDs</h3>
        </div>
        <div className="discover-tld-slider-box">
          <div className="discover-tld-slider splide">
            <div className="splide__track">
              <ul className="splide__list">{tldList}</ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainextensionTldlist;
