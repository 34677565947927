import React, { useState, useRef } from "react";
import ArrowDashLogo from "../Logos/ArrowDashLogo";
import MapContainer from "./MapContainer";
import { sendContactForm } from "../../Services/contactusapi";
import ReCAPTCHA from "react-google-recaptcha";

function ContactMapForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [issueError, setIssueError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageCountError, setMessageCountError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const recaptchaRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const isssueRef = useRef(null);
  const messageRef = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("1");
  const [message, setMessage] = useState("");
  const [msgcounter, setMsgcounter] = useState(250);
  const handleName = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleIssue = (e) => {
    e.preventDefault();
    setIssue(e.target.value);
  };
  const handleMessage = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
    setMsgcounter(250 - e.target.value.length);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if (email === "" || email.trim() === "") {
      setEmailError(true);
      setSuccessMessage(false);
    } else {
      setEmailError(false);
    }

    if (name === "" || name.trim() === "") {
      setNameError(true);
      setSuccessMessage(false);
    } else {
      setNameError(false);
    }

    if (issue === "1") {
      setIssueError(true);
      setSuccessMessage(false);
    } else {
      setIssueError(false);
    }

    if (message === "" || message.trim() === "") {
      setMessageError(true);
      setSuccessMessage(false);
    } else {
      setMessageError(false);
    }
    if (msgcounter < 0) {
      setMessageCountError(true);
      setSuccessMessage(false);
    } else {
      setMessageCountError(false);
    }

    if (recaptchaValue === "") {
      setCaptchaError(true);
      setSuccessMessage(false);
    } else {
      setCaptchaError(false);
    }

    if (
      email !== "" &&
      name !== "" &&
      issue !== "1" &&
      message !== "" &&
      recaptchaValue !== "" &&
      email.trim() !== "" &&
      name.trim() !== "" &&
      message.trim() !== "" &&
      !(msgcounter < 0)
    ) {
      /*bodydata*/
      let bodyFormData = new FormData();
      bodyFormData.append("site_id", "NDA=");
      bodyFormData.append("email", email);
      bodyFormData.append("fullname", name);
      bodyFormData.append("message", message);
      bodyFormData.append("issue", issue);

      sendContactForm(bodyFormData, function (response) {
        if (response.status === 1) {
          setSuccessMessage(true);
          setEmailError(false);
          setName("");
          setEmail("");
          setIssue("1");
          setMessage("");
          setMsgcounter(250);
          nameRef.current.value = "";
          emailRef.current.value = "";
          isssueRef.current.value = "1";
          messageRef.current.value = "";
          recaptchaRef.current?.reset();
        } else {
          setSuccessMessage(false);
          setEmailError(true);
        }
      });
    }
  };

  return (
    <>
      <div className="section-row">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="contact-map-box">
                <div className="contact-map">
                  <MapContainer />
                </div>
                <div className="contact-address-box font-l">
                  <h4>Abuse Contact</h4>
                  <p>
                    To report abuse in regard to any of the GoDaddy Registry
                    top-level domains on this website, please use the form or
                    contact us directly at:
                  </p>

                  <address>
                    100 S. Mill Avenue <br />
                    Suite 1600 <br />
                    Tempe, AZ 85281 USA
                    <br />
                  </address>
                  <p>Email: abuse [@] registry [.] godaddy</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-from-box">
                <div className="contact-from-title text-center">
                  <h4>Contact us for additional information and support.</h4>
                </div>
                {successMessage === true ? (
                  <div className="alert alert-success mb-3" id="UploadSuccess">
                    Thanks for contacting us! We will get in touch with you
                    shortly.
                  </div>
                ) : (
                  ""
                )}
                <div className="contact-from">
                  <form
                    action="#"
                    onSubmit={(e) => {
                      onsubmitHandler(e);
                    }}
                  >
                    <div className="form-group">
                      <label>
                        Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) => {
                          handleName(e);
                        }}
                        ref={nameRef}
                      />
                      {nameError === true ? (
                        <small className="text-danger">Please enter name</small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={(e) => {
                          handleEmail(e);
                        }}
                        ref={emailRef}
                      />
                      {emailError === true ? (
                        <small className="text-danger">
                          Please enter valid email
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Nature of Inquiry <span className="required">*</span>
                      </label>
                      <select
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="issue"
                        onChange={(e) => {
                          handleIssue(e);
                        }}
                        ref={isssueRef}
                      >
                        <option value="1">Open this select menu</option>

                        <option value="Trusted Registrar Partner Inquiry">
                          Trusted Registrar Partner Inquiry
                        </option>
                        <option value="Press/Media Inquiry">
                          Press/Media Inquiry
                        </option>
                        <option value="Registration Inquiry">
                          Registration Inquiry
                        </option>
                        <option value="Sales Inquiry">Sales Inquiry</option>
                        <option value="Other Inquiry">Other Inquiry</option>
                      </select>
                      {issueError === true ? (
                        <small className="text-danger">
                          Please select nature of inquiry
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="form-group"
                      style={{ position: `relative` }}
                    >
                      <label>
                        Message <span className="required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="message"
                        maxLength="250"
                        onChange={(e) => {
                          handleMessage(e);
                        }}
                        ref={messageRef}
                      ></textarea>
                      {messageError === true ? (
                        <small className="text-danger">
                          Please enter message
                        </small>
                      ) : (
                        ""
                      )}
                      {messageCountError === true ? (
                        <small className="text-danger">
                          Only accept 250 characters.
                        </small>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          color: `gray`,
                          right: `8px`,
                          top: `124px`,
                          position: `absolute`,
                        }}
                      >
                        {msgcounter}
                      </div>
                    </div>
                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                        ref={recaptchaRef}
                      />
                      {captchaError === true ? (
                        <small className="text-danger">
                          Please verify that you are not a robot
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary w-100"
                      >
                        <span>
                          Submit
                          <ArrowDashLogo />
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactMapForm;
