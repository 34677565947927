const fmenu = [
  ["Accredited Registrars", "accreditedregistrars", ""],
  ["Legal", "https://registry.godaddy/legal", "_blank"],
  ["Privacy", "https://registry.godaddy/legal/privacy-policy", "_blank"],
  ["WHOIS", "https://whois.dnrs.godaddy/", "_blank"],
  ["Policies", "policies", ""],
  [
    "Advertising Preferences",
    "http://preferences-mgr.truste.com/?pid=godaddy01&aid=godaddy01&type=godaddy",
    "_blank",
  ],
];
export default fmenu;
