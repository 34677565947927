import React, { useEffect } from "react";
import GoDaddyLogo from "../Logos/GoDaddyLogo";

const PremiumAiNavbar = (props) => {
  return (
    <div>
      <header id="header">
        <div className="container">
          <div className="d-flex align-items-center">
            <div className="logo">
              <a href="/" aria-label="Hub">
                <GoDaddyLogo />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default PremiumAiNavbar;
