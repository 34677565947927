import React from "react";

function AvailableDomainStatus({domain}) {
  return (
    <div className="pt-md-4 pb-md-5 py-4">
      <div className="container">
        <div className="small-container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column text-center py-5 px-4 tld-available-info-box">
                <div
                  className="bg-img d-none d-md-block"
                  style={{
                    backgroundImage: `url(./images/domain-available-desktop.jpg)`, 
                  }}                  
                ></div>
                <div
                  className="bg-img d-block d-md-none"
                  style={{
                    backgroundImage: `url(./images/domain-available-mobile.jpg)`, 
                  }}
                ></div>
                <div className="tld-available-info">
                  <h3>Your domain is available!</h3>
                  <h2>{domain}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailableDomainStatus;
