import React, { useState, useEffect } from "react";
import HomeFooter from "../Footer/HomeFooter";
import BlackNavBar from "../Partial/BlackNavBar";
import BlogRecent from "./BlogRecent";
import BlogSingle from "./BlogSingle";
import BlogSlider from "./BlogSlider";
import Pageloader from "../Partial/Pageloader";
import { Helmet } from "react-helmet";
import { getAllBlogs } from "../../Services/blogApi";
import { get_random } from "../../Services/randomTld";
function Blog() {
  const [timer, setTimer] = useState(false);
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    const getBlogList = async () => {
      const blogs = await getAllBlogs();
      setBlogData(blogs.data);
      setTimer(true);
    };
    getBlogList();
  }, []);
  return (
    <>
      <Helmet>
        <title>GoDaddy Registry Blog &#124; GoDaddy Registry Domain Hub</title>
        <meta name="website" content="http://domains.registry.godaddy/blog" />
        <meta
          name="description"
          content="Read the latest tips, tricks and news on everything from helping small businesses, entrepreneurship, domains, SEO and marketing."
        />
      </Helmet>
      <BlackNavBar />
      {timer === true ? (
        <>
          <div className="section-row pt-0">
            <div className="container">
              <div className="section-title">
                <div className="m-0">
                  <h1 className="h3 mb-0 pb-0">Blog</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  {blogData ? <BlogSlider blogData={blogData} /> : ""}
                </div>
                <div className="col-lg-4 mt-3 mt-lg-0">
                  {blogData ? (
                    <BlogSingle blogData={get_random(blogData)} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <hr className="m-0" />
          </div>

          {blogData ? <BlogRecent blogData={blogData} /> : ""}
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default Blog;
