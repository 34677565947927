import React from 'react';
import { useState, useEffect } from "react";
import { getSpotlightInfo } from "../../Services/homeApi";
import { Link } from "react-router-dom";
import SpotlightLogo from '../Logos/SpotlightLogo';

function DomainextensionFeatured(props) {

    const [spotlightData, setSpotlightData] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        let spotdata = await getSpotlightInfo();
        setSpotlightData(spotdata);
      };
      fetchData();
    }, []);

    const capitalLetter = (string) => {
        return string.toUpperCase();
      };


    return (
        <>
        {spotlightData !== null ? (
        <div className="section-row feature-row">
        <div className="container">
            <div className="section-row feature-main-box">
                <div className="banner-img d-none d-md-block" style={{backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${spotlightData.tld_bgimage}`,
            }}></div>
                <div className="banner-img d-block d-md-none" style={{backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${spotlightData.tld_bgimage_mobile})`,
            }}></div>
                <div className="feature-box text-center text-white px-4 px-md-5 py-5 py-md-4 my-md-5">
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <p className="font-xl mb-0">FEATURED</p>
                            <h2 className="mb-md-4 text-white"> .
                            {spotlightData.tld_name && capitalLetter(spotlightData.tld_name)}</h2>
                            <p className="font-l">{spotlightData.tld_spotlight_desc}</p>
                            <div className="text-center pt-3">
                                <Link href="#" className="btn btn-sm btn-secondary" to={
                                    `/${spotlightData.tld_name.toLowerCase()}`
                                }>
                                    <span>Get .{spotlightData.tld_name}  <SpotlightLogo /></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </div>):""}
    </>
    );
}

export default DomainextensionFeatured;