import React from "react";
import FacebookLogo from "../Logos/FacebookLogo";
import GoDaddyLogo from "../Logos/GoDaddyLogo";
import LinkedinLogo from "../Logos/LinkedinLogo";
import TwitterLogo from "../Logos/TwitterLogo";

function TopFooter(props) {
  return (
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col">
            <div className="flogo">
              <a href="https://registry.godaddy/" target={"_blank"}>
              <GoDaddyLogo />
              </a>
            </div>
          </div>
          <div className="col-md-6 col">
            <div className="f-social d-flex mt-0 justify-content-end">
              <a href="https://www.facebook.com/GoDaddyRegistry/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                <FacebookLogo/>
              </a>
              <a href="https://twitter.com/GoDaddyRegistry" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                <TwitterLogo />
              </a>
              <a href="https://www.linkedin.com/company/godaddyregistry/" aria-label="Linkedin" target="_blank" rel="noopener noreferrer">
                <LinkedinLogo/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFooter;
