import React from "react";

function AboutTitleSection() {
  return (
    <>
      <div className="section-row pb-0 pb-md-4">
        <div className="container">
          <div className="about-info-box">
            <div className="row justify-content-center text-center">
              <div className="col-md-10 col-xl-8">
                <h4 className="text-white mb-0">
                  GoDaddy Registry powers one of the world’s largest and most
                  diverse portfolios of top-level domains so you can find the
                  perfect name for your business, brand, or big idea.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutTitleSection;
