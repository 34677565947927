import React from "react";
import HubSearchBox from "../Partial/HubSearchBox";

function DomainextensionBanner(props) {
  return (
    <div className="tld-banner-box">
      <div className="hero-banner-slider-box d-flex align-items-end align-items-md-center">
        <div
          className="banner-img d-none d-md-block"
          style={{
            backgroundImage: `url(/images/domain-extensions-desktop.jpg)`,
          }}
        ></div>
        <div
          className="banner-img d-block d-md-none"
          style={{
            backgroundImage: `url(/images/domain-extensions-mobile.jpg)`,
          }}
        ></div>
        <div className="container">
          <div className="tld-text mt-md-auto text-white">
            <div className="hero-text">
              <p className="mb-2 mb-md-3">Products</p>
            </div>
            <h1 className="h2 text-white mb-0 mb-md-4 pb-3">
              Differentiate yourself from the competition.
            </h1>
            <div className="tld-search">
            <HubSearchBox url = {window.location.pathname}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DomainextensionBanner;
