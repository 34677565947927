import React from "react";
import HomeFooter from "../Footer/HomeFooter";

import WhiteNavBar from "../Partial/WhiteNavBar";
import AccrditedRegistrarPerfectdomain from "./AccrditedRegistrarPerfectdomain";
import AccreditedRegistrarsBanner from "./AccreditedRegistrarsBanner";
import AccreditedRegistrarsContents from "./AccreditedRegistrarsContents";

function AccreditedRegistrars() {
  return (
    <>
      <WhiteNavBar />
      <AccreditedRegistrarsBanner />
      <AccreditedRegistrarsContents />
      <AccrditedRegistrarPerfectdomain />
      <HomeFooter />
    </>
  );
}

export default AccreditedRegistrars;
