import { hasOnlySpecialCharater } from "./searchFunctions";
export const validateNoCharacter = (event) => {
  if (
    (event.charCode > 96 && event.charCode < 123) ||
    (event.charCode > 64 && event.charCode < 91) ||
    (event.charCode > 47 && event.charCode < 58) ||
    event.charCode === 46 ||
    event.charCode === 45 ||
    event.charCode === 189 ||
    event.charCode === 8 ||
    event.charCode === 13
  ) {
    return true;
  }
  event.preventDefault();
  return false;
};

export const validateBlank = (searchedText, event) => {
  if (searchedText !== "") {
    return true;
  }
  event.preventDefault();
  return false;
};

export const validSearch = (searchedText, event) => {
  if (searchedText.length > 0) {
    if (hasOnlySpecialCharater(searchedText) === true) {
      event.preventDefault();
      return false;
    } else if (searchedText.charCodeAt(0) === 46) {
      event.preventDefault();
      return false;
    } else if (hasOnlySpecialCharater(searchedText.split(".")[0]) === true) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  } else {
    event.preventDefault();
    return false;
  }
};

export const validDomain = (searchedText) => {
  if (searchedText.length > 0) {
    if (hasOnlySpecialCharater(searchedText) === true) {
      return false;
    } else if (searchedText.charCodeAt(0) === 46) {
      return false;
    } else if (hasOnlySpecialCharater(searchedText.split(".")[0]) === true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

/*export const validateNoCharacterDot = (event) => {
  if (
    (event.charCode > 96 && event.charCode < 123) ||
    (event.charCode > 64 && event.charCode < 91) ||
    (event.charCode > 47 && event.charCode < 58) ||
    event.charCode === 45 ||
    event.charCode === 189 ||
    event.charCode === 8 ||
    event.charCode === 13
  ) {
    return true;
  }
  event.preventDefault();
  return false;
};*/
