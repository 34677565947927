import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

import "./css/style.css";
import "./css/responsive.css";
import "./css/loader.css";
import React, { useEffect } from "react";
import { CookiesProvider } from "react-cookie";

import AppWithRouterAccess from "./AppWithRouterAccess";
import ContextProvider from "./Context/ContextProvider";

function App() {
  useEffect(() => {
    if (navigator.userAgent.indexOf("Mac OS X") != -1) {
      document.body.classList.add("mac");
    } else {
      document.body.classList.add("windows");
    }
  }, []);

  return (
    <ContextProvider>
      <CookiesProvider>
        <AppWithRouterAccess />
      </CookiesProvider>
    </ContextProvider>
  );
}

export default App;
