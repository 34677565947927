import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import WhiteDashLogoBlog from "../Logos/WhiteDashLogoBlog";
import blogData from "../../Constants/blog-detail/blogData";
import { useEffect } from "react";

function BlogRecent({ blogData }) {
  return (
    <>
      <div className="section-row">
        <div className="container">
          <div className="section-title text-center">
            <h3>Recent</h3>
          </div>
          <div className="recent-post-list">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 0: 1, 575: 2, 767: 3 }}
            >
              <Masonry gutter="1rem">
                {blogData.map((blogItem, index) => {
                  let tagString = "";

                  blogItem.tags.map((tags, index) => {
                    index + 1 === blogItem.tags.length
                      ? (tagString = tagString + tags.tag)
                      : (tagString = tagString + tags.tag + ", ");
                  });

                  return (
                    <div className="post-slider-box" key={index}>
                      <div className="post-img">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "uploads/blog_featured_image/" +
                            blogItem.Featured_Image
                          }
                          alt=""
                        />
                      </div>
                      <div className="post-slider-content">
                        <h3>
                          <a href={"/blog/" + blogItem.friendly_url}>
                            {blogItem.title}
                          </a>
                        </h3>
                        <span>
                          <a href={"/blog/" + blogItem.friendly_url}>
                            {
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: tagString,
                                }}
                              ></span>
                            }
                          </a>
                        </span>
                        <a
                          href={"/blog/" + blogItem.friendly_url}
                          className="post-slider-link"
                        >
                          <WhiteDashLogoBlog />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogRecent;
