import React, { useEffect } from "react";
import WhiteDashLogoBlog from "../Logos/WhiteDashLogoBlog";
import Splide from "@splidejs/splide";
import "@splidejs/react-splide/css";
import blogData from "../../Constants/blog-detail/blogData";

function BlogSlider({ blogData }) {
  useEffect(() => {
    var blogslider = new Splide(".blog-slider", {
      type: "loop",
      perPage: 1,
      perMove: 1,
      padding: "0",
      cover: true,
      lazyLoad: "nearby",
      autoplay: true,
      interval: 5000,
    });
    blogslider.mount();
  });

  return (
    <>
      <div className="blog-slider-box">
        <div className="blog-slider splide">
          <div className="splide__track">
            <ul className="splide__list">
              {blogData.map((blogItem, index) => {
                if (index <= 3) {
                  let tagString = "";

                  blogItem.tags.map((tags, index) => {
                    index + 1 === blogItem.tags.length
                      ? (tagString = tagString + tags.tag)
                      : (tagString = tagString + tags.tag + ", ");
                  });

                  return (
                    <li className="splide__slide" key={index}>
                      <div className="post-main-slider-box">
                        <div className="post-slider-box">
                          <div
                            className="bg-img"
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_API_URL}uploads/blog_featured_image/${blogItem.Featured_Image})`,
                            }}
                          ></div>
                          <div className="post-slider-content">
                            <h4>
                              <a href={"/blog/" + blogItem.friendly_url}>
                                {blogItem.title}
                              </a>
                            </h4>
                            <span>
                              <a href={"/blog/" + blogItem.friendly_url}>
                                {
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: tagString,
                                    }}
                                  ></span>
                                }
                              </a>
                            </span>
                            <a
                              href={"/blog/" + blogItem.friendly_url}
                              className="post-slider-link"
                            >
                              <WhiteDashLogoBlog />
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogSlider;
