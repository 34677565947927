import React, { useEffect, useState } from "react";
import { getAllAccreditedRegistrars } from "../../Services/homeApi";
import LoaderIcon from "../Logos/Loader";

import DataTable from "react-data-table-component";
import Pageloader from "../Partial/Pageloader";

function AccreditedRegistrarsContents() {
  const [flagforLoader, setFlagforLoader] = useState(0);
  const [accreditedRegistrars, setAccreditedRegistrars] = useState([]);
  const [filteredaccreditedRegistrars, setFilteredaccreditedRegistrars] =
    useState([]);
  const [search, setSearch] = useState("");

  const STORY_HEADERS = [
    {
      name: "Registrar Name",
      selector: (row) => row.registrars_name,
      sortable: true,
      sortFunction: (a, b) =>
        compareIgnoreCase(a.registrars_name, b.registrars_name),
    },
    {
      name: "Country",
      selector: (row) => row.registrars_country,
      sortable: true,
    },
    {
      name: "registrars_url",
      selector: (row) => row.registrars_url,
      omit: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const accreditedRegistrars = async () => {
      let data = await getAllAccreditedRegistrars();
      const accData = [];
      data.map((tld) => {
        let t = {
          registrars_id: tld.id,
          registrars_name: tld.registrars_name,
          registrars_country: tld.registrars_country,
          registrars_url: tld.registrars_url,
        };
        accData.push(t);
        return accData;
      });

      setAccreditedRegistrars(accData);
      setFilteredaccreditedRegistrars(accData);
      setFlagforLoader(1);
    };
    accreditedRegistrars();
  }, []);

  //console.log(accreditedRegistrars);

  const onRowClicked = (row, event) => {
    let url = row.registrars_url;
    if (url === "") {
      event.preventDefault();
    } else {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    const result = accreditedRegistrars.filter((accreditedRegistrars) => {
      return accreditedRegistrars.registrars_name
        .toLowerCase()
        .match(search.toLowerCase());
    });
    setFilteredaccreditedRegistrars(result);
  }, [search]);

  function compareIgnoreCase(a, b) {
    let r1 = a.toLowerCase();
    let r2 = b.toLowerCase();
    if (r1 < r2) {
      return -1;
    }
    if (r1 > r2) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      {flagforLoader === 1 ? (
        <div className="section-row">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="card p-3 p-md-4 accredited-registrars-list">
                  <DataTable
                    columns={STORY_HEADERS}
                    data={filteredaccreditedRegistrars}
                    pagination={true}
                    //defaultSortFieldId={1}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                    onRowClicked={onRowClicked}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    defaultSortAsc={false}
                    subHeader={true}
                    subHeaderComponent={
                      <input
                        type="text"
                        placeholder="Enter Registrars Name..."
                        className="w-25 form-control"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default AccreditedRegistrarsContents;
