import React from "react";
import faqInfo from "../../Constants/faq/config.json";

function FaqQuestionAnswers() {
  return (
    <>
      {faqInfo["faq"].map((posFields, index) => {
        //console.log(posFields["title"]);
        return (
          <div className="section-row pt-0" key={index}>
            <div className="container">
              <div className="section-title text-center">
                <h4>
                  <strong>{posFields["title"]}</strong>
                </h4>
              </div>

              <div className="faq-accordion">
                <div className="row justify-content-center">
                  <div className="ccol-xl-8 col-lg-10">
                    <div className="accordion">
                      {posFields["posts"].map((postdata, i) => {
                        //console.log(postdata);
                        //console.log(postdata["question"]);
                        return (
                          <div
                            className="accordion-item"
                            id={"faqAccordion" + index + i}
                            key= {i}
                          >
                            <h2
                              className="accordion-header"
                              id="flush-headingOne"
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={
                                  "#Registry-DomainsOne" + index + i
                                }
                                aria-expanded="false"
                                aria-controls="Registry-DomainsOne"
                              >
                                {postdata["question"]}
                              </button>
                            </h2>
                            <div
                              id={"Registry-DomainsOne" + index + i}
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingOne"
                              data-bs-parent={"#faqAccordion" + index + i}
                            >
                              <div
                                  dangerouslySetInnerHTML={{__html:postdata["answer"] }}
                                   className="accordion-body font-l">
                               </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default FaqQuestionAnswers;
