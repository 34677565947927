import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { get_random, randomTld } from "../../Services/randomTld";
import { getAllBlogs } from "../../Services/blogApi";
import { Helmet } from "react-helmet";
import { duplicateBlogs } from "../../Constants/duplicateBlogs";

function BlogDetailsContent({ blogData }) {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const fulldate = new Date(blogData.publish_on);
  const monthname = month[fulldate.getMonth()];
  const monthdate = fulldate.getDate();
  const year = fulldate.getFullYear();
  const publishedDate = monthname + " " + monthdate + ", " + year;
  const [nextRead, setNextRead] = useState({});
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    const getBlogList = async () => {
      const blogs = await getAllBlogs();
      setBlogList(blogs.data);
    };
    getBlogList();
  }, []);

  useEffect(() => {
    if (blogList.length > 0) {
      blogList.map((blog, index) => {
        if (blog.id === blogData.id) {
          blogList.splice(index, 1);
        }
      });
      setNextRead(get_random(blogList));
    }
  }, [blogList]);

  return (
    <>
      <Helmet>
        <title>{blogData.title}| GoDaddy Registry Domain Hub</title>
        <meta
          name="website"
          content={`http://domains.registry.godaddy/blog/${blogData.friendly_url}`}
        />
        <meta name="description" content={blogData.seo_keyword} />
        <meta property="og:type" content="Article" />
        <meta
          property="og:url"
          content={`http://domains.registry.godaddy/blog/${blogData.friendly_url}`}
        />
        <meta name="title" property="og:title" content={blogData.title} />
        <meta
          name="description"
          property="og:description"
          content={blogData.seo_keyword}
        />
        <meta
          name="image"
          property="og:image"
          content={
            process.env.REACT_APP_API_URL +
            "uploads/blog_featured_image/" +
            blogData.Featured_Image
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@GoDaddyRegistry" />
        <meta name="twitter:title" content={blogData.title} />
        <meta name="twitter:description" content={blogData.seo_keyword} />
        <meta
          name="twitter:image"
          content={
            process.env.REACT_APP_API_URL +
            "uploads/blog_featured_image/" +
            blogData.Featured_Image
          }
        />
        {blogData.friendly_url in duplicateBlogs ? (
          <link
            rel="canonical"
            href={duplicateBlogs[blogData.friendly_url]}
          ></link>
        ) : (
          ""
        )}
      </Helmet>
      {
        <div className="section-row pt-0">
          <div className="container">
            <div className="post-main-box ps-0">
              <div className="post-img">
                <figure>
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "uploads/blog_featured_image/" +
                      blogData.Featured_Image
                    }
                    alt={blogData.title}
                  />
                </figure>
              </div>
              <div className="post-content-info mt-lg-5">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="post-content-box">
                      <h1 className="h3">
                        {blogData.short_tag_line === ""
                          ? blogData.title
                          : blogData.short_tag_line}
                      </h1>
                      <div className="post-author-box d-flex align-items-center">
                        <figure>
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              "uploads/blog_author_image/" +
                              blogData.author_image
                            }
                            alt={blogData.author_name}
                          />
                        </figure>
                        <div className="post-author-info d-md-flex align-items-center">
                          <h5>{blogData.author_name}</h5>
                          <p>{publishedDate}</p>
                        </div>
                      </div>
                      <div
                        className="font-l"
                        dangerouslySetInnerHTML={{
                          __html: blogData.summary,
                        }}
                      ></div>
                    </div>
                    <div className="my-4">
                      <hr className="my-4" />
                      <div className="next-article-link-box d-flex">
                        <div className="next-article-label">Read Next:</div>
                        <div className="next-article-link">
                          <a href={`/blog/${nextRead.friendly_url}`}>
                            {nextRead.title}
                          </a>
                        </div>
                      </div>
                      <hr className="my-4" />
                    </div>

                    <div className="post-author-bottom-box">
                      <div className="post-author-box d-md-flex align-items-center">
                        <figure>
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              "uploads/blog_author_image/" +
                              blogData.author_image
                            }
                            alt={blogData.author_name}
                          />
                        </figure>
                        <div className="post-author-info">
                          <h5 className="m-0">{blogData.author_name}</h5>
                          <span>
                            {blogData.author_designation
                              ? blogData.author_designation
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default BlogDetailsContent;
