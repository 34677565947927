import React, { useState, useEffect } from "react";
import HomeFooter from "../Footer/HomeFooter";
import WhiteNavBar from "../Partial/WhiteNavBar";
import PoliciesBanner from "./PoliciesBanner";
import PoliciesContainer from "./PoliciesContainer";
import Pageloader from "../Partial/Pageloader";

function Policies() {
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 300);
  }, []);

  return (
    <>
      <WhiteNavBar />
      {timer === true ? (
        <>
          <PoliciesBanner />
          <PoliciesContainer />
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default Policies;
