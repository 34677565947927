import React, { useEffect, useState } from "react";
import ArrowDashLogo from "../Logos/ArrowDashLogo";
import { getAllDrawerChannelPartners } from "../../Services/homeApi";
import { useCookies } from "react-cookie";

function AvailableFeatureRegistrar({ tld_id, domain }) {
  const [allFeaturedChannelPartner, setAllFeaturedChannelPartner] = useState(
    []
  );

  const [cookies, setCookie] = useCookies(['utm']);
  const utm_source =  cookies.utm_source === undefined || cookies.utm_source === "" ? (""):cookies.utm_source;
  const utm_campaign = cookies.utm_campaign === undefined || cookies.utm_campaign === "" ? (""):cookies.utm_campaign;
  const utm_medium = cookies.utm_medium === undefined || cookies.utm_medium === "" ? (""):cookies.utm_medium;
  const utm_term = cookies.utm_term === undefined || cookies.utm_term === "" ? (""):cookies.utm_term;

  useEffect(() => {
    if (tld_id !== null || tld_id === "") {
      const allFeaturedChannelPartner = async (tld_id) => {
        let data = await getAllDrawerChannelPartners(tld_id);
        let newdata = data.sort((a, b) =>
          a.icann_short_name.localeCompare(b.icann_short_name)
        );
        setAllFeaturedChannelPartner(newdata);
      };
      allFeaturedChannelPartner(tld_id);
    }
  }, []);

  let timeout;
  function timeout_init(param) {
    window.dataLayer.push({
      event: "Registrar Select Click",
      page: {
        url: window.location.href,
        title: "HUB",
      },
    });

    timeout = setTimeout(() => {
      timeout_trigger(param);
    }, 3000);
  }

  
  function timeout_trigger(param) {
    // let newTab = window.open('', '_blank');
    // newTab.location.href = param;
    window.location = param;
    //window.open(param,"_self");
  }


  function timeout_clear() {
    clearTimeout(timeout);
  }

  return (
    <>
      {Object.keys(allFeaturedChannelPartner).length > 0 ? (
        <>
          <div className="trusted-partner-main-list">
            <div className="row g-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
              {Object.keys(allFeaturedChannelPartner).map(function (
                featurekey,
                index
              ) {
                let drawer_url =
                  allFeaturedChannelPartner[featurekey].drawer_url;
                let drawer_url_replace = drawer_url.replaceAll("example", domain);
                drawer_url_replace += "&utm_source="+utm_source+"&utm_campaign="+utm_campaign+"&utm_medium="+utm_medium+"&utm_term="+utm_term;

                return (
                  <div className="col" key={index}>
                    <div className="card text-md-center">
                      <figure>
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "" +
                            allFeaturedChannelPartner[featurekey].logo_url
                          }
                          alt={allFeaturedChannelPartner[featurekey].icann_short_name}
                        />
                      </figure>
                      <p className="font-xl">
                        {allFeaturedChannelPartner[featurekey].icann_short_name}
                      </p>
                      <a
                        href={drawer_url_replace}
                        className="btn btn-sm btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target={"#domainModal" + index}
                        onClick={() => timeout_init(drawer_url_replace)}
                      >
                        <span>
                          Select <ArrowDashLogo />
                        </span>
                      </a>
                    </div>
                    <div
                      className="domain-model modal fade"
                      id={"domainModal" + index}
                      tabIndex="-1"
                      aria-labelledby="domainModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            <h3>
                              You’ve selected&nbsp;
                              {allFeaturedChannelPartner[featurekey].icann_short_name}
                            </h3>
                            <p className="font-xl">
                              Sit tight, we’ll redirect you to complete your
                              purchase.
                            </p>
                            <p>
                              Or you may&nbsp;
                              <a
                                href="/#"
                                data-bs-dismiss="modal"
                                onClick={() => timeout_clear()}
                              >
                                cancel your selection
                              </a>
                              &nbsp;and return to the search results.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bg-light border mt-4 p-4 rounded-3 text-center">
            <h5 className="mb-2">
              <em><strong>Retailers are sorted in alphabetical order.</strong></em>
            </h5>
            <p className="m-0">
              There are many registrars and resellers of domains, please check
              with your favorite provider or registrar in addition to those
              listed above. Not all retailers displayed sell all GoDaddy
              Registry available domains.
            </p>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AvailableFeatureRegistrar;
