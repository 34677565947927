import React from "react";

function FaqBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="page-title-banner d-flex align-items-end align-items-md-center">
          <div
            className="banner-img d-none d-md-block"
            style={{
              backgroundImage: `url(/images/faq-page-desktop.jpg)`,
            }}
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
              backgroundImage: `url(/images/faq-page-mobile.jpg)`,
            }}
          ></div>
          <div className="container">
            <div className="page-title-text text-center text-white">
              <h1 className="text-white mb-0 pb-0">Frequently Asked Questions</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section-row pt-0"></div>
    </>
  );
}

export default FaqBanner;