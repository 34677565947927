import React from "react";
import Splide from "@splidejs/splide";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowLogo from "../Logos/ArrowLogo";
import SearchIcon from "../Logos/SearchIcon";
import "@splidejs/react-splide/css";
import { validateNoCharacter } from "../../Services/validations";
import ArrowDashLogo from "../Logos/ArrowDashLogo";
import regex from "../../Constants/regValidation";
import { hasOnlySpecialCharater } from "../../Services/searchFunctions";

function TldsMenu(props) {
  let tld = [];
  let tl = [];
  let tllist = [];

  const [searchedText, setSearchedText] = useState("");
  props.tldData.map((tl) => {
    var t = [];
    t.push(tl.tld_name);
    t.push(tl.tld_bgimage);
    t.push(tl.tld_bgimage_mobile);
    t.push(tl.tld_placeholder);
    t.push(tl.tld_heading);
    t.push(tl.tld_desc);

    return tld.push(t);
  });
  const [domainContainsALLChar, setDomainContainsALLChar] = useState(false);

  useEffect(() => {
    if (searchedText.length > 0) {
      if (hasOnlySpecialCharater(searchedText) === true) {
        setDomainContainsALLChar(true);
      } else if (searchedText.charCodeAt(0) === 46) {
        setDomainContainsALLChar(true);
      } else if (hasOnlySpecialCharater(searchedText.split(".")[0]) === true) {
        setDomainContainsALLChar(true);
      } else {
        setDomainContainsALLChar(false);
      }
    }
  }, [searchedText]);

  useEffect(() => {
    var main = new Splide(".main-slider", {
      type: "fade",
      heightRatio: 0.5,
      pagination: false,
      arrows: false,
      cover: true,
      drag: false,
      start: 2,
    });

    var thumbnails = new Splide(".thumbnail-slider", {
      type: "loop",
      isNavigation: true,
      start: 2,
      gap: 0,
      focus: 3,
      pagination: false,
      cover: true,
      direction: "ttb",
      heightRatio: 3.1,
      perPage: 15,
      autoplay: true,
      interval: 5000,
      breakpoints: {
        1399: {
          heightRatio: 2.3,
          perPage: 14,
        },
        1199: {
          heightRatio: 2.2,
          perPage: 14,
        },
        991: {
          //heightRatio: 1.7,
          perPage: 7,
        },
        767: {
          heightRatio: 1.6,
          perPage: 7,
        },
        575: {
          heightRatio: 1.6,
          perPage: 7,
        },
        419: {
          heightRatio: 1.8,
          perPage: 6,
        },
      },
    });

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
    thumbnails.on("click", function () {
      var Autoplay = thumbnails.Components.Autoplay;
      Autoplay.pause();
    });
  }, [props.tldData]);

  tld.map((tldata, index) => {
    return tl.push(
      <li key={`slide-${index}`} className="splide__slide">
        <div className="hero-banner-slider-box d-flex align-items-end align-items-md-center">
          <div
            className="banner-img d-none d-md-block"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${tldata[1]})`,
            }}
          ></div>
          <div
            className="banner-img d-block d-md-none"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}${tldata[2]})`,
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-7 col-xl-7 col-md-8">
                <div className="hero-text mt-md-auto text-white">
                  <h1>{tldata[4]}</h1>
                  <div className="hero-text-details">
                    <p>{tldata[5]}</p>
                  </div>
                  <div className="tld-search">
                    <form action="#">
                      <div className="tld-search-form">
                        <span className="search-ico">
                          <SearchIcon />
                        </span>
                        <input
                          type="text"
                          className="form-control DomainSearch"
                          value={searchedText}
                          placeholder={tldata[3]}
                          onKeyPress={(e) => validateNoCharacter(e)}
                          onChange={(e) => {
                            let text = e.target.value;

                            if (regex.test(text)) {
                              setSearchedText("");
                            } else {
                              setSearchedText(text);
                            }
                          }}
                        />
                        <Link
                          to={
                            searchedText
                              ? domainContainsALLChar === false
                                ? `/search?domain=${searchedText}`
                                : "/"
                              : "/"
                          }
                          state={{ refurl: window.location.origin, pageId: 1 }}
                        >
                          <button
                            type="submit"
                            className="search-btn btn btn-primary"
                          >
                            <span className="search-ico">
                              <SearchIcon />
                            </span>
                            <span>
                              Search Domains <ArrowDashLogo />
                            </span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                  <div className="tld-link">
                    <a href={"/" + tldata[0].toLowerCase()}>
                      Learn more about <span>.{tldata[0]}</span>
                      <ArrowLogo />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  });

  tld.map((tldata, index) => {
    return tllist.push(
      <li key={`slide-${index}`} className="splide__slide">
        <span className="tld-name">.{tldata[0]}</span>
      </li>
    );
  });

  return (
    <>
      <div className="home-slider-main-box">
        <div className="home-slider-box">
          <div className="main-slider splide" id="main-slider">
            <div className="splide__track">
              <ul className="splide__list">{tl}</ul>
            </div>
          </div>
        </div>

        <div className="tld-slider-main-box">
          <div className="tld-slider-box">
            <div
              className="tld-slider thumbnail-slider splide"
              id="thumbnail-slider"
            >
              <div className="splide__track">
                <ul className="splide__list">{tllist}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TldsMenu;
