import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UtmContext } from "../../Context/ContextProvider";
import { getTldInfo, getTrackSearchAPI } from "../../Services/homeApi";
import { useCookies } from "react-cookie";

function GetitWhite({ actualDomain }) {
  const navigate = useNavigate();
  const { domainToTrack } = useContext(UtmContext);
  
  const [cookies, setCookie] = useCookies(['utm']);
  const utm_source =  cookies.utm_source === undefined || cookies.utm_source === "" ? (""):cookies.utm_source;
  const utm_campaign = cookies.utm_campaign === undefined || cookies.utm_campaign === "" ? (""):cookies.utm_campaign;
  const utm_medium = cookies.utm_medium === undefined || cookies.utm_medium === "" ? (""):cookies.utm_medium;
  const utm_term = cookies.utm_term === undefined || cookies.utm_term === "" ? (""):cookies.utm_term;

  const getButtonClick = (actualDomain) => {
    if (actualDomain !== domainToTrack) {
      let tld_name = actualDomain.substring(actualDomain.indexOf(".") + 1);
      const tldInfoConst = async (tld_name) => {
        let data = await getTldInfo(tld_name);
        const pageRef = window.location.origin;
        const availability = "a";
        getTrackSearch(
          actualDomain,
          data[0].id,
          pageRef,
          utm_source,
          utm_campaign,
          utm_medium,
          utm_term,
          availability
        );
      };
      tldInfoConst(tld_name); //call

      const getTrackSearch = async (
        domain,
        tldId,
        pageRef,
        utm_source,
        utm_campaign,
        utm_medium,
        utm_term,
        availability
      ) => {
        let data = await getTrackSearchAPI(
          domain,
          tldId,
          pageRef,
          utm_source,
          utm_campaign,
          utm_medium,
          utm_term,
          availability
        );
      };
    }
    navigate("/available", { state: { domain: actualDomain } });
  };
  return (
    <button
      type="submit"
      className="btn btn-sm btn-primary btn-outline"
      onClick={() => getButtonClick(actualDomain)}
    >
      <span>
        Get It
        <svg
          width="20"
          height="13"
          viewBox="0 0 20 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0853 0.5L13 1.60097L17.0407 5.7H0V7.3H17.0407L13 11.399L14.0853 12.5L20 6.5L14.0853 0.5Z"
          ></path>
        </svg>
      </span>
    </button>
  );
}

export default GetitWhite;
