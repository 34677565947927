import React from "react";
import { useState, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  removeSpecialCharacter,
  isTldAvailable,
  isValidTld,
  arrayForDomainSearch,
  getAvailabilityStatus,
  domainStatus,
} from "../../Services/searchFunctions";
import {
  getAllTlds,
  getSuggestedDomainWithLimit,
  getTrackSearchAPI,
  getTldInfo,
} from "../../Services/homeApi";
import topPriorityTldlist from "../../Constants/topPriorityTldlist";
import DomainDisplayBox from "./DomainDisplayBox/DomainDisplayBox";
import TldList from "./TldList/TldList";
import UnsupportedTlds from "./UnsupportedTlds";
import Pageloader from "../Partial/Pageloader";
import BlackNavBar from "../Partial/BlackNavBar";
import SearchBox from "../Partial/SearchBox";
import HomeFooter from "../Footer/HomeFooter";
import SearchProgressBar from "./SearchProgressBar";
import { UtmContext } from "../../Context/ContextProvider";
import { useCookies } from "react-cookie";
import { validDomain } from "../../Services/validations";

function Search(props) {
  const {
    domainToTrack,
    setDomainToTrack,
  } = useContext(UtmContext);

  
  

  const [tldName, setTldName] = useState("");
  const [tldList, setTldList] = useState([]);
  const [searchArray, setSearchArray] = useState([]);
  const [avaialbleStatus, setAvaialbleStatus] = useState([]);
  const [primaryTldBox, setPrimaryTldBox] = useState([]);
  const [suggestedDomainData, setSuggestedDomainData] = useState([]);
  const [exclusiveTld, setExclusiveTld] = useState([]);
  const [unsupportedTlds, setUnsupportedTlds] = useState(false);
  const [allAvailableList, setAllAvailableList] = useState([]);
  const [noExactMatch, setNoExactMatch] = useState("d-none");
  const { state } = useLocation();
  const [refUrl, setRefUrl] = useState(state && state.refurl);
  const [searchPageId, setSearchPageId] = useState(state && state.pageId);
  const [cookiesChecked, setCookiesChecked] = useState(false);

  const [cookies, setCookie] = useCookies(['domain']);
  const utm_source =  cookies.utm_source === undefined || cookies.utm_source === "" ? (""):cookies.utm_source;
  const utm_campaign = cookies.utm_campaign === undefined || cookies.utm_campaign === "" ? (""):cookies.utm_campaign;
  const utm_medium = cookies.utm_medium === undefined || cookies.utm_medium === "" ? (""):cookies.utm_medium;
  const utm_term = cookies.utm_term === undefined || cookies.utm_term === "" ? (""):cookies.utm_term;

  //GA4 event tracking 
  useEffect(() => {
    window.dataLayer.push({
      event: "domain search click",
      page: {
        url: window.location.href,
        title: "HUB",
      },
    });
  }, []);

  if (refUrl === null) {
    setRefUrl(window.location.origin);
    setSearchPageId(3);
  }

  //get domain
  const search = useLocation().search;
  let domain = "";
  if (new URLSearchParams(search).get("domain") !== null) {
    const domainWithChar = new URLSearchParams(search)
      .get("domain")
      .toLowerCase();
    domain = removeSpecialCharacter(domainWithChar);
    if (validDomain(domain) === false) {
      domain = "";
    }
  }

  //domain name

  let domainName = "";
  if (domain !== "") {
    domainName = domain.split(".");
  }

  //setcontext if cookies is set

  useEffect(() => {
    if (cookies.domainTracked === domain) {
      setDomainToTrack(domain);
    }
    setCookiesChecked(true);
  }, []);

  //get tld name if available
  useEffect(() => {
    if (domainName.length > 1) {
      setTldName(isTldAvailable(domain));
    }
  }, []);

  //get all tld list

  useEffect(() => {
    const getData = async () => {
      const data = await getAllTlds();
      return setTldList(data);
    };
    getData();
  }, []);

  //get search array to serach availability status
  useEffect(() => {
    if (tldList.length > 0 && domainName.length > 0) {
      setSearchArray(arrayForDomainSearch(domainName[0], tldList));
    }
  }, [tldList]);

  //get status of domain for all tlds
  useEffect(() => {
    if (searchArray.length > 0) {
      const getData = async () => {
        const data = await getAvailabilityStatus(searchArray);
        setAvaialbleStatus(data);
      };
      getData();
    }
  }, [searchArray]);

  //tracking without tld
  useEffect(() => {
    if (cookiesChecked === true) {
      if (domainName.length === 1 && domainToTrack !== domain) {
        const tldId = 0;
        const availability = "NA";
        setDomainToTrack(domain);
        setCookie("domainTracked", domain, { path: "/" });
        const searchTrack = async () => {
          await getTrackSearchAPI(
            domainName[0],
            tldId,
            refUrl,
            utm_source,
            utm_campaign,
            utm_medium,
            utm_term,
            availability,
            searchPageId
          );
        };
        searchTrack();
      }
    }
  }, [cookiesChecked]);

  //tracking domain with tld

  useEffect(() => {
    if (tldList.length > 0 && tldName !== "" && domainToTrack !== domain) {
      //supported tld tracking
      if (isValidTld(tldName, tldList)) {
        var tld_id = "";
        var tld_whois_server = "";
        const tldInfo = async () => {
          let data = await getTldInfo(tldName);
          tld_id = data[0].id;
          tld_whois_server = data[0].tld_whois_server;

          const getDomainStatus = async () => {
            const domaindata = await domainStatus(
              domainName[0],
              tldName,
              tld_whois_server
            );
            let trackingavailableStatus = "";
            if (domaindata.data.status === "Not_Available") {
              trackingavailableStatus = "r";
            } else if (domaindata.data.status === "Available") {
              trackingavailableStatus = "a";
            }

            return trackingavailableStatus;
          };
          const domainAvailability = await getDomainStatus();
          setDomainToTrack(domain);
          setCookie("domainTracked", domain, { path: "/" });
          const track = async () => {
            await getTrackSearchAPI(
              domain,
              tld_id,
              refUrl,
              utm_source,
              utm_campaign,
              utm_medium,
              utm_term,
              domainAvailability,
              searchPageId
            );
          };
          track();
        };
        tldInfo();
      } else {
        //unsupported tLD tracking
        const availability = "NA";
        const tld_id = 0;
        setDomainToTrack(domain);
        setCookie("domainTracked", domain, { path: "/" });
        const searchTrack = async () => {
          let trackResponse = await getTrackSearchAPI(
            domain,
            tld_id,
            refUrl,
            utm_source,
            utm_campaign,
            utm_medium,
            utm_term,
            availability,
            searchPageId
          );
        };
        searchTrack();
      }
    }
  }, [tldList]);

  //domain with tld

  useEffect(() => {
    if (tldName !== "") {
      //if tld is from list of all tlds
      if (isValidTld(tldName, tldList) && avaialbleStatus.length > 0) {
        topPriorityTldlist.map((tld, index) => {
          if (tld === tldName) {
            topPriorityTldlist.splice(index, 1);
          }
          return topPriorityTldlist;
        });
        setExclusiveTld(topPriorityTldlist);

        avaialbleStatus.map((tld, index) => {
          if (tld.data.domain === domain) {
            let primaryData = [];
            primaryData.push(avaialbleStatus.splice(index, 1)[0]);
            const suggestedData = async () => {
              const suggestedData = await getSuggestedDomainWithLimit(
                domainName[0],
                [tldName],
                12
              );
              //console.log(suggestedData);
              suggestedData.Domains.map((suggest, index) => {
                if (suggest.DomainName === domain) {
                  suggestedData.Domains.splice(index, 1);
                }
              });
              primaryData.push({
                data: {
                  domain: suggestedData.Domains.splice(0, 1)[0].DomainName,
                  status: "Available",
                },
              });
              //console.log(suggestedData);
              //console.log(primaryData);
              const suggestedDomains = await getSuggestedDomainWithLimit(
                domainName[0],
                topPriorityTldlist,
                40
              );
              if (suggestedData.Domains.length > 10) {
                suggestedData.Domains.splice(
                  suggestedData.Domains.length - 1,
                  1
                );
              }

              setSuggestedDomainData([
                ...suggestedData.Domains,
                ...suggestedDomains.Domains,
              ]);
              //console.log(suggestedDomainData);
              setPrimaryTldBox(primaryData);
            };
            
            suggestedData();
          }
        });
        setAllAvailableList([...avaialbleStatus]);
      }
      //domain search with unsupported tlds
      else {
        if (avaialbleStatus.length > 0) {
          const suggestedData = async () => {
            const suggestedDomains = await getSuggestedDomainWithLimit(
              domainName[0],
              topPriorityTldlist,
              50
            );
            setSuggestedDomainData(suggestedDomains.Domains);
            setUnsupportedTlds(true);
            setAllAvailableList(avaialbleStatus);
          };
          suggestedData();
        }
      }
    }
    //domain search without tld
    else {
      if (avaialbleStatus.length > 0) {
        const availablepriorityTld = [];
        const otherTld = [];
        let tldCount = 0;

        //sort available tlds into priority tlds and other tlds
        avaialbleStatus.map((tld) => {
          if (tld.data.status === "Available") {
            tldCount = tldCount + 1;
            for (let i = 0; i < topPriorityTldlist.length; i++) {
              if (
                topPriorityTldlist[i] ===
                tld.data.domain
                  .substring(tld.data.domain.indexOf(".") + 1)
                  .toLowerCase()
              ) {
                availablepriorityTld.push(tld);
                break;
              } else if (i + 1 === topPriorityTldlist.length) {
                otherTld.push(tld);
              }
            }
          } else {
            tldCount = tldCount + 1;
          }
        });

        //case 1 : when 2 or more priority tlds have status = available

        if (availablepriorityTld.length >= 2) {
          const eliminatedDomain = availablepriorityTld.splice(0, 2);
          setPrimaryTldBox(eliminatedDomain);
          setAllAvailableList([...availablepriorityTld, ...otherTld]);
          const suggestedData = async () => {
            const suggestedDomains = await getSuggestedDomainWithLimit(
              domainName[0],
              topPriorityTldlist,
              50
            );
            suggestedDomains.Domains.map((suggestedDomain, index) => {
              eliminatedDomain.map((availabledata) => {
                if (availabledata.data.domain === suggestedDomain.DomainName) {
                  suggestedDomains.Domains.splice(index, 1);
                }
              });
              return setSuggestedDomainData(suggestedDomains.Domains);
            });
          };

          suggestedData();
        }

        //case 2 : when only 1 priority tlds have status = available
        if (availablepriorityTld.length === 1 && otherTld.length >= 1) {
          setPrimaryTldBox([...availablepriorityTld, ...otherTld.splice(0, 1)]);
          setAllAvailableList([...otherTld]);
          const suggestedData = async () => {
            const suggestedDomains = await getSuggestedDomainWithLimit(
              domainName[0],
              topPriorityTldlist,
              50
            );

            suggestedDomains.Domains.map((suggestedDomain, index) => {
              if (
                availablepriorityTld[0].data.domain ===
                suggestedDomain.DomainName
              ) {
                suggestedDomains.Domains.splice(index, 1);
              }

              return setSuggestedDomainData(suggestedDomains.Domains);
            });
          };

          suggestedData();
        }

        //case 3: when no priority tlds have status = available and other tlds have status = available
        if (availablepriorityTld.length === 0 && otherTld.length >= 2) {
          setPrimaryTldBox([...otherTld.splice(0, 2)]);
          setAllAvailableList([...otherTld]);
          const suggestedData = async () => {
            const suggestedDomains = await getSuggestedDomainWithLimit(
              domainName[0],
              topPriorityTldlist,
              50
            );
            setSuggestedDomainData(suggestedDomains.Domains);
          };

          suggestedData();
        }

        //case 4: when no priority tlds have status = available and only one other tlds have status = available
        if (availablepriorityTld.length === 0 && otherTld.length === 1) {
          const suggestedData = async () => {
            const suggestedDomains = await getSuggestedDomainWithLimit(
              domainName[0],
              topPriorityTldlist,
              51
            );
            setPrimaryTldBox([
              ...otherTld,
              {
                data: {
                  domain: suggestedDomains.Domains.splice(0, 1)[0].DomainName,
                  status: "Available",
                },
              },
            ]);
            setSuggestedDomainData(suggestedDomains.Domains);
          };

          suggestedData();
        }

        //case 5: when no priority tlds have status = available and no other tlds have status = available
        if (
          availablepriorityTld.length === 0 &&
          otherTld.length === 0 &&
          tldCount === tldList.length
        ) {
          const suggestedData = async () => {
            const suggestedDomains = await getSuggestedDomainWithLimit(
              domainName[0],
              topPriorityTldlist,
              52
            );
            setPrimaryTldBox([
              {
                data: {
                  domain: suggestedDomains.Domains.splice(0, 1)[0].DomainName,
                  status: "Available",
                },
              },
              {
                data: {
                  domain: suggestedDomains.Domains.splice(
                    suggestedDomains.Domains.length - 1,
                    1
                  )[0].DomainName,
                  status: "Available",
                },
              },
            ]);
            setSuggestedDomainData(suggestedDomains.Domains);
            setNoExactMatch("border-bottom border-top mt-4 py-3 text-muted");
          };

          suggestedData();
        }
      }
    }
  }, [avaialbleStatus]);

  return (
    <>
      {domain ? (
        <>
          {avaialbleStatus.length > 0 && suggestedDomainData.length >= 30 ? (
            <>
              <BlackNavBar />
              <SearchBox domain={domain} />
              <SearchProgressBar />
              {unsupportedTlds === true ? (
                <UnsupportedTlds domain={domain} />
              ) : (
                <>
                  <div className="container text-center ">
                    <div className="small-container">
                      <h5 className={noExactMatch}>
                        There are no exact matches available for your request.
                        Try one of these great alternatives:
                      </h5>
                    </div>
                  </div>
                  <DomainDisplayBox domains={primaryTldBox} />
                </>
              )}
              <TldList
                domainAvail={allAvailableList}
                suggestedDomainData={suggestedDomainData}
                exclusiveTld={exclusiveTld}
                domainName={domainName}
              />
              <HomeFooter />
            </>
          ) : (
            <Pageloader />
          )}
        </>
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
}

export default Search;
