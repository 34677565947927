import axios from "axios";

export const getAllCategoryTlds = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/home/getAllCategoryWiseTlds.php?site_id=${process.env.REACT_APP_SITE_KEY}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllTlds API", error);
  }
};
