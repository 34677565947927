import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PremiumAiBanner from "./PremiumAiBanner";
import PremiumContactForm from "./PremiumContactForm";
import HomeFooter from "../Footer/HomeFooter";
import PremiumAiNavbar from "./PremiumAiNavbar";

function PremiumAi() {
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 300);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Contact GoDaddy Registry &#124; GoDaddy Registry Domain Hub
        </title>
        <meta
          name="website"
          content="http://domains.registry.godaddy/contact"
        />
        <meta
          name="description"
          content="Want to get in touch with GoDaddy Registry? We’d love to hear from you so why not drop us a line and someone will be in touch."
        />
      </Helmet>
      <PremiumAiNavbar></PremiumAiNavbar>
      <PremiumAiBanner></PremiumAiBanner>
      <PremiumContactForm></PremiumContactForm>
      <HomeFooter></HomeFooter>
    </>
  );
}

export default PremiumAi;
