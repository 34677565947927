import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "./css/responsive.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import DomainSearch from "./Components/DomainSearch/DomainSearch";
import Available from "./Components/Available/Available";
import TldDetailTemplate from "./Components/TldDetail/TldDetailTemplate";
import Faq from "./Components/Faq/Faq";
import About from "./Components/About/About";
import ContactUs from "./Components/ContactUs/ContactUs";
import Policies from "./Components/Policies/Policies";
import Domainextension from "./Components/Domainextension/Domainextension";
import Blog from "./Components/Blog/Blog";
import BlogDetails from "./Components/Blog/BlogDetails";
import AccreditedRegistrars from "./Components/AccreditedRegistrars/AccreditedRegistrars";
import Search from "./Components/Search/Search";
import PremiumAi from "./Components/PremiumAI/PremiumAi";
import NotFound from "./Components/NotFound/NotFound";

function AppWithRouterAccess(props) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/available" element={<Available />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/accreditedregistrars"
          element={<AccreditedRegistrars />}
        />
        <Route path="/policies" element={<Policies />} />
        <Route path="/domainextension" element={<Domainextension />} />
        <Route path={"/:tld_name"} element={<TldDetailTemplate />} />
        <Route path={"/:tld_name/:language"} element={<TldDetailTemplate />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/premium-domains" element={<PremiumAi />} />
        <Route path="/blog/:blog_name" element={<BlogDetails />} />
        <Route path="*" element={<Navigate to="/page-not-found" />} />
        <Route path="/page-not-found" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppWithRouterAccess;
