import axios from "axios";
export const getAllBlogs = async () => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/blog/blog-list.php?site_id=${process.env.REACT_APP_SITE_KEY}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getAllBlogs API", error);
  }
};

export const getblogContent = async (blog_name) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/hub/blog/blog-list.php?blog_page=${blog_name}`
    );
    return response.data;
  } catch (error) {
    console.log("errror while calling getblogContent API", error);
  }
};
