import React, { useEffect, useState } from "react";
import GetitWhite from "../../Partial/GetitWhite";
import { getSuggestedDomainWithLimit } from "../../../Services/homeApi";
function SuggestedDomainList({
  suggestedDomainData,
  exclusiveTld,
  domainName,
}) {
  return (
    <>
      {suggestedDomainData.length > 0 ? (
        <div
          className="tab-pane fade show active"
          id="SuggestedTld"
          role="tabpanel"
          aria-labelledby="SuggestedTld-tab"
        >
          <div className="tld-tabs-list">
            {suggestedDomainData.map((domain, index) => {
              return (
                <div className="tld-tabs-box" key={index}>
                  <div className="tld-tabs-title-box d-sm-flex align-items-center">
                    <div className="tld-name-tabs-box d-sm-flex align-items-center flex-row-reverse me-auto">
                      <h4 className="my-2 me-sm-3">
                        {domain.DomainName.split(".")[0]}
                        <strong>.{domain.DomainName.split(".")[1]}</strong>
                      </h4>
                    </div>
                    <GetitWhite
                      actualDomain={domain.DomainName.toLowerCase()}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default SuggestedDomainList;
