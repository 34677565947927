import React ,{useEffect} from "react";
import BottomFooter from "./BottomFooter";
import TopFooter from "./TopFooter";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

function HomeFooter({language}) {
  const [cookies, setCookie] = useCookies(['utm']);
  const search = useLocation().search; 
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (new URLSearchParams(search).get("utm_source") !== null) {
      const utm_source = new URLSearchParams(search).get("utm_source");
      setCookie("utm_source", utm_source, { path: "/" });
    }
    if (new URLSearchParams(search).get("utm_campaign") !== null) {
      const utm_campaign = new URLSearchParams(search).get("utm_campaign");
      setCookie("utm_campaign", utm_campaign, { path: "/" });
    }
    if (new URLSearchParams(search).get("utm_medium") !== null) {
      const utm_medium = new URLSearchParams(search).get("utm_medium");
      setCookie("utm_medium", utm_medium, { path: "/" });
    }
    if (new URLSearchParams(search).get("utm_term") !== null) {
      const utm_term = new URLSearchParams(search).get("utm_term");
      setCookie("utm_term", utm_term, { path: "/" });
    }
  }, []);


  return (
    <footer id="footer">
      <TopFooter />
      <BottomFooter language={language}/>
    </footer>
  );
}

export default HomeFooter;
