import React, { useState, useEffect } from "react";
import HomeFooter from "../Footer/HomeFooter";
import WhiteNavBar from "../Partial/WhiteNavBar";
import AboutBanner from "./AboutBanner";
import AboutFooterSection from "./AboutFooterSection";
import AboutTitleSection from "./AboutTitleSection";
import AboutWhySection from "./AboutWhySection";
import Pageloader from "../Partial/Pageloader";
import {Helmet} from "react-helmet";

function About() {
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimer(true);
    }, 300);
  }, []);

  return (
    <>
      <Helmet>
        <title>About GoDaddy Registry &#124; GoDaddy Registry Domain Hub</title> 
        <meta name="website" content="http://domains.registry.godaddy/about"/>
        <meta name="description" content="GoDaddy Registry powers one of the largest and most diverse portfolios of domains so you can find the perfect name for your business, brand, or big idea."/>
      </Helmet>
      <WhiteNavBar />
      {timer === true ? (
        <>
          <AboutBanner />
          <AboutTitleSection />
          <AboutWhySection />
          <AboutFooterSection />
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default About;
