import React, { useState, useEffect } from "react";
import HomeFooter from "../Footer/HomeFooter";
import BlackNavBar from "../Partial/BlackNavBar";
import BlogDetailsContent from "./BlogDetailsContent";
import Pageloader from "../Partial/Pageloader";
import { useParams, Navigate } from "react-router-dom";
import { getblogContent } from "../../Services/blogApi";
function BlogDetails() {
  const [timer, setTimer] = useState(false);
  const [noMatchingBlog, setNoMatchingBlog] = useState("");
  const [blogContent, setBlogContent] = useState({});
  const urlParams = useParams();
  const blog_name = urlParams.blog_name;
  useEffect(() => {
    const getBlogDetails = async () => {
      const blogData = await getblogContent(blog_name);
      if (blogData.totalRecords === 0) {
        setNoMatchingBlog("1");
      } else {
        const filteredBlogData = blogData.data.filter(
          (blog) => blog.site_key === 89
        );
        if (filteredBlogData.length === 0) {
          setNoMatchingBlog("1");
        } else {
          setTimer(true);
          setBlogContent(filteredBlogData);
        }
      }
    };
    getBlogDetails();
  }, []);

  return (
    <>
      <BlackNavBar />{" "}
      {noMatchingBlog === "1" ? (
        <Navigate to={"/page-not-found"} />
      ) : timer === true ? (
        <>
          <BlogDetailsContent blogData={blogContent[0]} />
          <HomeFooter />
        </>
      ) : (
        <Pageloader />
      )}
    </>
  );
}

export default BlogDetails;
