import React from "react";

function WhosUsing(tldInfo) {
  const showcase = tldInfo["tldInfo"]["who_is_using_section"].showcase;
  return (
    <>
      <div className="section-row who-using-tld-row">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="section-title text-center">
                <h2 className="mb-3 mb-md-4">
                  {tldInfo["tldInfo"]["who_is_using_section"].heading}
                </h2>
                <p className="mw-100">
                  {tldInfo["tldInfo"]["who_is_using_section"].small_desc}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 who-using-tld-list">
            <div className="row g-md-4 g-3">
              {showcase.map((showcases) => {
                return Object.entries(showcases).map(
                  ([websiteName, image], index) => (
                    <div className="col-md-4" key={index}>
                      <div className="who-using-tld-box text-center">
                        <figure>
                          <img
                            src={"/images/whoisusingit/" + image}
                            alt={websiteName}
                          />
                        </figure>
                        <span className="font-xl">{websiteName}</span>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
          {/* <div className="mt-md-5 mt-4 text-center tld-site-link">            
              <p className="font-xl mb-1"
                dangerouslySetInnerHTML={{
                  __html:
                    tldInfo["tldInfo"]["who_is_using_section"].show_your_site,
                }}
                onClick={(e)=>
                {
                  e.preventDefault();
                }}
              />            
          </div> */}
        </div>
      </div>
    </>
  );
}

export default WhosUsing;
