import React from 'react'

function LoaderIcon() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style={{ enableBackground: "enable-background:new 0 0 100 100" }}>
      <g>
        <path d="M30,4.1c-5.1,2.2-10,5.4-14.2,9.4l3.8,3.8c4,4,10.1,5,15.2,2.5c4.2-2.1,8.8-3.3,13.6-3.5C48.3,6.7,38.7,0.4,30,4.1z"/>
        <path d="M4.1,70c2.2,5.1,5.4,10,9.4,14.2l3.8-3.8c4-4,5-10.1,2.5-15.2c-2.1-4.2-3.3-8.8-3.5-13.6C6.7,51.7,0.4,61.3,4.1,70z"/>
        <path d="M95.9,30c-2.2-5.1-5.4-10-9.4-14.2l-3.8,3.8c-4,4-5,10.1-2.5,15.2c2.1,4.2,3.3,8.8,3.5,13.6C93.3,48.3,99.6,38.7,95.9,30z"/>
        <path d="M70,95.9c5.1-2.2,10-5.4,14.2-9.4l-3.8-3.8c-4-4-10.1-5-15.2-2.5c-4.2,2.1-8.8,3.3-13.6,3.5C51.7,93.3,61.3,99.6,70,95.9z"/>
        <path d="M72.8,24.9c-3.5-3.3-7.6-5.6-12.1-7.1c-5.4-1.7-9.1-6.8-9.1-12.5V0c5.9,0.3,11.5,1.3,16.8,3.4C77,6.8,79.5,18.2,72.8,24.9z"/>
        <path d="M3.4,31.6C1.3,36.9,0.3,42.6,0,48.3h5.3c5.6,0,10.7-3.7,12.5-9.1c1.6-4.5,3.9-8.5,7.2-12.1C18.2,20.5,6.8,23,3.4,31.6z"/>
        <path d="M96.6,68.4c2.1-5.3,3.2-10.9,3.4-16.7h-5.3c-5.6,0-10.7,3.7-12.5,9.1c-1.6,4.5-3.9,8.5-7.2,12.1C81.8,79.5,93.2,77,96.6,68.4z"/>
        <path d="M31.6,96.6c5.3,2.1,10.9,3.2,16.7,3.4v-5.3c0-5.6-3.7-10.7-9.1-12.5c-4.5-1.6-8.5-3.9-12.1-7.2C20.5,81.8,23,93.2,31.6,96.6z"/>
      </g>
    </svg>
  )
}

export default LoaderIcon