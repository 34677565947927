import React, { useEffect, useState } from "react";
import SearchIcon from "../Logos/SearchIcon";
import { Link } from "react-router-dom";
import { validateNoCharacter } from "../../Services/validations";
import ArrowDashLogo from "../Logos/ArrowDashLogo";
import regex from "../../Constants/regValidation";
import { hasOnlySpecialCharater } from "../../Services/searchFunctions";

function HubSearchBox({ url }) {
  const [searchedText, setSearchedText] = useState("");
  const [domainContainsALLChar, setDomainContainsALLChar] = useState(false);

  useEffect(() => {
    if (searchedText.length > 0) {
      if (hasOnlySpecialCharater(searchedText) === true) {
        setDomainContainsALLChar(true);
      } else if (searchedText.charCodeAt(0) === 46) {
        setDomainContainsALLChar(true);
      } else if (hasOnlySpecialCharater(searchedText.split(".")[0]) === true) {
        setDomainContainsALLChar(true);
      } else {
        setDomainContainsALLChar(false);
      }
    }
  }, [searchedText]);

  return (
    <>
      <form action="#">
        <div className="tld-search-form">
          <span className="search-ico">
            <SearchIcon />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Find your perfect domain"
            onKeyPress={(e) => validateNoCharacter(e)}
            onChange={(e) => {
              let text = e.target.value;
              if (regex.test(text)) {
                setSearchedText("");
              } else {
                setSearchedText(text);
              }
            }}
          />
          <Link
            to={
              searchedText
                ? domainContainsALLChar === false
                  ? `/search?domain=${searchedText}`
                  : `${url}`
                : `${url}`
            }
            state={{ refurl: window.location.origin+''+window.location.pathname, pageId: 1 }}
          >
            <button type="submit" className="search-btn btn btn-primary">
              <span className="search-ico">
                <SearchIcon />
              </span>
              <span>
                Search Domains <ArrowDashLogo />
              </span>
            </button>
          </Link>
        </div>
      </form>
    </>
  );
}

export default HubSearchBox;
