const fmenu = [
  ["Registradores Acreditados", "accreditedregistrars", ""],
  ["Legal", "https://registry.godaddy/legal", "_blank"],
  ["Privacidad", "https://registry.godaddy/legal/privacy-policy", "_blank"],
  ["WHOIS", "https://whois.dnrs.godaddy/", "_blank"],
  ["Políticas", "policies", ""],
  [
    "Preferencias Publicitarias",
    "http://preferences-mgr.truste.com/?pid=godaddy01&aid=godaddy01&type=godaddy",
    "_blank",
  ],
];
export default fmenu;
