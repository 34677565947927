import React from "react";
import GetitButton from "../../Partial/GetitButton";

function DomainAvailBox({ domain }) {
  

  let actualDomain =
    (domain.data.domain.split(".")[0] +
    "." +
    domain.data.domain.substring(domain.data.domain.indexOf(".") + 1)).toLowerCase();  

  return (
    <div className="h-100 d-flex flex-column justify-content-center text-md-center bg-light py-4 py-md-5 px-4 tld-info-box">
      <h3>
        {domain.data.domain.split(".")[0]}
        <strong>
          .{domain.data.domain.substring(domain.data.domain.indexOf(".") + 1).toLowerCase()}
        </strong>
      </h3>

      <div className="text-md-center">
        <GetitButton actualDomain = {actualDomain}/> 
      </div>
      
    </div>
  );
}

export default DomainAvailBox;
