import React, { useEffect, useState } from "react";

function WhyUs(tldInfo) {
  const question_answer = tldInfo["tldInfo"]["why_section"].question_answer;

  const question_count = question_answer.length;
  const [columnSize, setColumnSize] = useState(0);

  useEffect(() => {
    if (question_count > 0) {
      if (question_count % 2 === 0) {
        setColumnSize(6);
      } else {
        setColumnSize(4);
      }
    }
  }, [question_count]);
  
  return (
    <>
      <div className="section-row tld-cell-row">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="section-title text-md-center">
                <h2 className="mb-3 mb-md-4">
                  {tldInfo["tldInfo"]["why_section"].heading}
                </h2>
                <p className="mw-100">
                  {tldInfo["tldInfo"]["why_section"].small_desc}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 tld-cell-list">
            <div className="row g-md-4 g-3">
              {question_answer.map((questions) => {
                return Object.entries(questions).map(
                  ([question, answer], index) => (
                    <div className={`col-md-${columnSize}`} key={index}>
                      <div className="tld-cell-box">
                        <h4>{question}</h4>
                        <p>{answer}</p>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyUs;
