import axios from "axios";

//remove characters from domain if available
export const removeSpecialCharacter = (domain) => {
  return domain.replace(/[&\/\\#,+()$~% '":*?<>{}^]/g, "");
};

//check whether tld is specified or not
export const isTldAvailable = (domain) => {
  let tld = "";
  if (domain.split(".").length > 1) {
    tld = domain.substring(domain.indexOf(".") + 1).toLowerCase();
  }
  return tld;
};

//is tld avaialble in supported tld list

export const isValidTld = (tldName, tldList) => {
  let data = false;
  tldList.map((tld) => {
    if (tld.tld_name.toLowerCase() === tldName) {
      data = true;
    }
  });

  return data;
};

//create array for all tlds to search in whois api
export const arrayForDomainSearch = (domainName, tldList) => {
  let searchArray = [];
  if (tldList.length > 0) {
    tldList.map((tld) => {
      return searchArray.push(
        axios.get(
          `${
            process.env.REACT_APP_SEARCHDOMAINAPI_URL
          }/cms/hub/whoisapi.php?domain=${domainName}&tld=${tld.tld_name.toLowerCase()}&whois_server=${
            tld.tld_whois_server
          }`
        )
      );
    });
  }
  return searchArray;
};

//get status of searched domain

export const getAvailabilityStatus = async (searcharray) => {
  try {
    let response = await Promise.all(searcharray);
    return response;
  } catch (error) {
    console.log("errror while calling getAvailabilityStatus API", error);
  }
};

//get random tlds
export const randomTld = (list, num) => {
  return list.sort(() => 0.5 - Math.random()).slice(0, num);
};
//shuffle array element
export const shuffle = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

//get domain status for single tld

export const domainStatus = async (domain, tld, tldServer) => {
  try {
    let response = await axios.get(
      `${
        process.env.REACT_APP_SEARCHDOMAINAPI_URL
      }/cms/hub/whoisapi.php?domain=${domain}&tld=${tld.toLowerCase()}&whois_server=${tldServer}`
    );
    return response;
  } catch (error) {
    console.log("errror while calling domainStatus API", error);
  }
};

//domain has only character

export const hasOnlySpecialCharater = (val) => {
  const pattern = /^[^a-zA-Z0-9]+$/;
  return pattern.test(val);
};
